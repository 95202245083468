/**
 * Global Alerts
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions } from "store"
import StyxAlert from "components/alerts/styx_alert"
import { getAlertDismissalStatusFromCookie } from "@/utils/cookies"
import { Alert } from "@/store/alerts/reducer"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly position: "header" | "footer"
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const GlobalAlerts = (props: Props) => {
    /**
     * Application state
     */

    const alerts = useSelector(state => state.alerts)
    const [items, setItems] = React.useState<readonly Alert[]>([])

    /**
     * Lifecycle
     */
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (alerts.status === "pristine") {
            // fetch only once for the componene4t that may be resused in multiple places
            if (props?.position === "footer") {
                dispatch(actions.fetchAlerts())
            }
            if (props?.position === "header") {
                //fetching mps extension response
                dispatch(actions.fetchMemberPolicyServiceStatus())
            }
        }
    }, [])

    /**
     * Retrieve alerts with their dismissal status.
     * @returns Alerts with dismissal status i.e. isDismissed field.
     */
    const fetchAlertsWithDismissalStatus = async(): Promise<readonly Alert[]> => {
        // Filter out valid alerts.
        const validAlerts = alerts.items.filter((alert) => alert?.alertId?.length && alert?.isValid)

        // Return all alerts with dismissible status.
        return validAlerts.map((alert) => ({
            ...alert,
            // Initialize alerts dismissible status only if they dismissible.
            isDismissed: alert?.isDismissable?
                getAlertDismissalStatusFromCookie(alert.alertId, alert.type): 
                undefined
        }))
    }

    /**
     * Filter all the alerts and set only the alerts that we need to show using setItems.
     * FYI: Alerts that we will show are the alerts that are valid and are not dismissed.
     */
    const setAlertsToShow = async (): Promise<void> => {
        // Get the mps status.
        const mpsStatus = alerts?.mpsData?.extensions?.metadata?.status
        // Retrieve alerts with dismissible statuses.
        const alertsWithDismissalStatus = await fetchAlertsWithDismissalStatus()

        // Filter the alerts to find the alerts to show.
        const alertsToShow: readonly Alert[] = alertsWithDismissalStatus.filter(alert => {
            // Define mps down alert id.
            const mpsDownAlertId = "alertid1711641243978"
            // Filter by `error` alert `level`
            const isError = alert.level === "error"
            // Filter by `warning` alert `level` for MPS down warning
            const isWarning = alert.level === "warning"

            // Return alerts by `position`
            if (props.position === "header") {
                if (mpsStatus === "partial" && alert.alertId === mpsDownAlertId) {
                    return alert.isValid && !alert.isDismissed && (isError || isWarning)
                }

                return alert.isValid && !alert.isDismissed && isError
            } else {
                if (alert.alertId === mpsDownAlertId) {
                    return false
                }

                return alert.isValid && !alert.isDismissed && !isError
            }
        })

        setItems(alertsToShow)
    }

    // Once alerts are available then set the alerts that we need to show.
    const areAlertsAvailable = alerts.status === "completed" && alerts.items.length
    React.useEffect(() => {
        if (areAlertsAvailable) {
            setAlertsToShow()
        }
    }, [areAlertsAvailable]) 

    /**
     * Template
     */
    if (!items.length) return null
    return (
        <div id="global-alerts">
            {items.map((alert, index) => (
                <StyxAlert
                    key={index}
                    name={`global-alert-${alert?.alertId || index}`}
                    {...alert}
                />
            ))}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default GlobalAlerts
