/**
 * Glance Cobrowse - Terms modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Button, Link, Modal } from "elements"
import Routes from "router"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly showModal: boolean
    readonly setShowModal: (b: boolean) => void
}
/**
 * Define template variables
*/
const glanceStart = () => {
    // see glance cobrowse docs: https://help.glance.net/glance-cobrowse/glance-cobrowse-customizing/visitor_agent/visitor/#using-startsession
    globalThis.GLANCE.Cobrowse.Visitor.startSession("GLANCE_KEYTYPE_RANDOM")
}
/**
 * Component
 * -----------------------------------------------------------------------------
 */
const CobrowseTermsModal = (props: Props) => {
    return (
        <Modal
            name="cobrowse-terms"
            size="medium"
            title="Share your screen and cobrowse with an agent"
            isVisible={props.showModal}
            onDismiss={() => props.setShowModal(false)}
            noBorder={true}
            noDismiss={true}
        >
            <Modal.Content>
                <p>To start a cobrowse session, call <Link name="cobrowse-terms-support-link" href={Routes.support()}>Support</Link> first.</p>
                <p><strong>Terms and conditions</strong></p>
                <p>Cobrowse enables the agent to guide you through this site. By accepting, you consent to sharing your screen and your Regence.com information with customer support.</p>
                <p>Regence cannot access your files or control your computer, and customer support will not be able to see your password, financial information, or any other websites or programs visible on your screen.</p>
                <p>Screen sharing is not required by Regence, and you can end the session at any time.</p>
            </Modal.Content>
            <Modal.Footer className="text-right">
                <Button
                    name="decline-terms"
                    label="Decline"
                    variant="link"
                    onClick={() => props.setShowModal(false)}
                />
                <Button
                    name="accept-terms"
                    label="Accept"
                    variant="primary"
                    className="ml-xs"
                    onClick={glanceStart}
                />
            </Modal.Footer>
        </Modal>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default CobrowseTermsModal
