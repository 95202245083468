/**
 * Chat Input
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions } from "store"
import { Button, Input } from "elements"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly children?: React.ReactNode
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ChatInput = (props: Props) => {
    /**
     * Refs
     */
    const ref = React.useRef<HTMLDivElement>(null)

    /**
     * Application state
     */
    const chat = useSelector(state => state.chat)

    /**
     * Component state
     */
    const [input, setInput] = React.useState("")

    /**
     * Lifecycle: When visibility changes
     */
    React.useEffect(() => {
        chat.isVisible ? ref.current?.focus() : ref.current?.blur()
    }, [chat.isVisible])

    /**
     * Methods
     */
    const dispatch = useDispatch()
    const sendMessage = () => {
        if (input && !isInitializing && !chat.isChatEnded) {
            dispatch(actions.sendMessage({ input: input, value: input }))
            setInput("")
            ref.current?.focus()
        }
    }
    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        sendMessage()
    }

    /**
     * Define template variables
     */
    const isError = chat.status === "error"
    const isInitializing = chat.status !== "completed"

    /**
     * Template
     */

    // prettier-ignore
    return (
        <div
            className={`h-xxxl bg-gray-25 dark:bg-dark-100 shadow-inset flex items-center px-xs rounded-b ${props.className || ""}`}
        >
            <form onSubmit={onSubmit} className="w-full">
                <div className="flex">
                    <Input
                        name="chat-input"
                        placeholder="Send message"
                        value={input}
                        setValue={setInput}
                        onChange={e => setInput(e.currentTarget.value)}
                        className="w-full"
                        spellCheck={false}
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoComplete="off"
                        disabled={isInitializing || isError || chat.isChatEnded}
                        label="chat-input"
                        hideLabel
                        inputStyles="placeholder-gray-300 border-none"
                    />
                    <Button
                        name={chat.intentContext || "submit-input-button"}
                        icon="sent"
                        variant="link"
                    />
                </div>
            </form>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ChatInput
