/**
 * Alerts – Styx test alert
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Alert, Markdown } from "elements"
import { convertTemplateString } from "@/utils/markdown"
import { setAlertDismissalStatusInCookie } from "@/utils/cookies"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Props {
    readonly alertId: string
    readonly level?: "default" | "error" | "success" | "info" | "warning"
    readonly header?: string
    readonly body?: string
    readonly type?: "per session" | "per user"
    readonly isDismissable?: boolean
    readonly isValid?: boolean
    readonly hasIcon?: boolean
    readonly isDismissed?: boolean
    readonly name: string
    readonly onDismiss?: VoidFunction
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const StyxAlert: React.FC<Props> = props => {

    const setDismissed = async () => {
        if (props.isDismissable && !props.isDismissed && props.alertId) {
            setAlertDismissalStatusInCookie(props.alertId, props.type, true)
        } else {
            console.warn(`Cannot dismiss alert ${props.type}`, props.alertId, props.isDismissable, props.isDismissed)
        }
    }
    
    /**
     * NOTE: Force `body` to a string, as global-alerts can be unpredicable
     */
    const body = convertTemplateString(props.body?.toString() || "")

    /*
     * Template
     */
    return (
        <Alert
            name={props.name}
            variant={props.level}
            hideIcon={!props.hasIcon}
            onDismiss={props.isDismissable ? setDismissed : undefined}
        >
            {props.header && <h6>{props.header}</h6>}
            <Markdown
                content={body}
                config={{ ADD_ATTR: ["target"] }}
                linkNameString={"alert-body-"} />
        </Alert>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default StyxAlert
