/**
 * Glance Cobrowse - include script & initialize
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { actions, useDispatch, useSelector } from "store"
import { createGlanceScript, debugLog, getCobrowseConfig, getPresenceVisitorId, setVisitorId } from "@/utils/glance"
import { CobrowseSessionEndReason } from "@/store/cobrowse/reducer"


/**
 * Types
 * -----------------------------------------------------------------------------
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const GlanceIncludeScript = (props: Props) => {
    /**
     * Hooks
     */

    /**
     * Application state
     */
    const dispatch = useDispatch()
    const account = useSelector(state => state.account)
    const cobrowse = useSelector(state => state.cobrowse)

    /**
     * Component state
     */
    /**
     * Define template variables
     */
    const config = getCobrowseConfig(window.location.hostname)

    /**
     * Methods
     */

    /**
     * Create and add Cobrowse <script> tag element to the page
     */
    const addGlanceScript = () => {
        const scriptTag = createGlanceScript(config)
        document.head.appendChild(scriptTag)
    }

    React.useEffect(() => {
        if (cobrowse.isInitialized) {
            dispatch(actions.listenForCobrowseTermsModalDisplay())
        }
    }, [cobrowse.isInitialized])

    /**
     * Lifecycle: Listen for all steps of cobrowse initialization to be complete, set isInitialized = true
     * - isGlanceScriptLoaded
     * - isEventHandlersAdded
     * - isPresenceReady (or is config.presence not "on")
     * - globalThis.GLANCE.Cobrowse exists (redundant check just b/c)
     *
     * OUTCOME: cobrowse.isInitialized = true (in redux store, ie you can listen for cobrowse.isInitialized)
     */
    React.useEffect(() => {
        debugLog(`Glance Init checks. isGlanceScriptLoaded:${cobrowse.isGlanceScriptLoaded}, isEventHandlersAdded:${cobrowse.isEventHandlersAdded}, isPresenceReady:${cobrowse.isPresenceReady}, config.presence=${config.presence}`)
        // check all dependencies (and presence config). also an extra redundant check that globalThis.GLANCE.Cobrowse is truthy
        if (cobrowse.isGlanceScriptLoaded && cobrowse.isEventHandlersAdded && (cobrowse.isPresenceReady || config.presence !== "on") && !!globalThis.GLANCE.Cobrowse) {
            dispatch(actions.receiveCobrowseUpdate({isInitialized: true}))
        }
    }, [cobrowse.isGlanceScriptLoaded, cobrowse.isEventHandlersAdded, cobrowse.isPresenceReady])

    /**
     * Lifecycle: Runs once. Add Glance cobrowse script, if it's not already on the page
     */
    React.useEffect(() => {
        // add the script, if it has not been added yet
        if (document.getElementById(config.scriptId)) {
            debugLog("glance script already exists on page")
            return
        }

        debugLog("adding glance script")
        addGlanceScript()
        dispatch(actions.listenForCobrowseScriptLoaded())
    }, [])

    /**
     * Lifecycle: add glance cobrowse event handlers
     */
    React.useEffect(() => {
        if (cobrowse.isGlanceScriptLoaded) {
            if (!globalThis.GLANCE?.Cobrowse?.Visitor) {
                console.error("something went wrong initializing cobrowse")
                return
            }

            /**
             * See Glance docs on 'sessionend' event:
             * https://help.glance.net/glance-cobrowse/glance-cobrowse-customizing/visitor_agent/visitor_event/#session-lifecycle-events
             */
            globalThis.GLANCE.Cobrowse.Visitor.addEventListener("sessionend", (event: { readonly reason: CobrowseSessionEndReason }) => {
                const { reason } = event
                debugLog("Glance cobrowse sessionend, reason:", reason)
                
                /*
                    this is a workaround/fix for a glance cobrowse bug (in their backlog),
                    where ending session during PDF sharing does not remove a "style='overflow: hidden;'" attribute on both <html> and <body> tags,
                    yielding broken scrolling etc
                */
                document.querySelector("html").removeAttribute("style")
                document.querySelector("body").removeAttribute("style")

                dispatch(actions.receiveCobrowseSessionEnd({reason}))
            })

            dispatch(actions.receiveCobrowseUpdate({isEventHandlersAdded: true}))
        }
    }, [cobrowse.isGlanceScriptLoaded])

    /**
     * Lifecycle: Glance/cobrowse presence initialization (set visitorId)
     */
    React.useEffect(() => {
        if (cobrowse.isGlanceScriptLoaded && account.isInitialized && config.presence === "on") {
            debugLog("cobrowse and account initialized, memeCk:", account?.profile?.memeCk)

            if (!globalThis.GLANCE) {
                console.error("no glance - something went wrong loading cobrowse script")
                return
            }

            if (!globalThis.GLANCE.Presence) {
                console.error("no glance presence - ensure you have data-presence='on'")
                return
            }

            if (!globalThis.GLANCE.Presence.Visitor) {
                console.error("glance visitor is not defined/null")
                return
            }

            setVisitorId(account.profile.memeCk)

            dispatch(actions.receiveCobrowseUpdate({isPresenceReady: true}))
        }
    }, [cobrowse.isGlanceScriptLoaded, account.isInitialized])

    /**
     * Lifecycle: just showing presence can be listened for
     */
    React.useEffect(() => {
        if (cobrowse.isPresenceReady) {
            debugLog("presence is ready! visitorId:", globalThis.GLANCE.Presence.Visitor.visitorid)
        }
    }, [cobrowse.isPresenceReady])

    /**
     * Lifecycle: example of how to use this elsewhere in the app (ie running code dependent on Glance script being initialized)
     */
    React.useEffect(() => {
        if (cobrowse.isInitialized) {
            debugLog("OMG COBROWSE IS LOADED BABY!")

            if (config.presence === "on") {
                const visitorId = getPresenceVisitorId()
                debugLog("visitorId from Presence instance:", visitorId)
            } else {
                debugLog("presence is off")
            }
        }
    }, [cobrowse.isInitialized])

    /**
     * Template - the only markup is added on initial load (in useEffect lifecycle above)
     */
    return null
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default GlanceIncludeScript
