/**
 * Global Alerts Spacer
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector } from "store"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const GlobalAlertsSpacer = () => {
    /**
     * Application state
     */
    const alerts = useSelector(state => state.alerts)

    /**
     * Lifecycle
     */
    React.useEffect(() => updateSpacerHeight(), [alerts.items])
    React.useEffect(() => {
        window.addEventListener("resize", updateSpacerHeight)
        return () => window.removeEventListener("resize", updateSpacerHeight)
    }, [])

    /**
     * Methods
     */
    const updateSpacerHeight = () => {
        const alertsElem = document.getElementById("global-alerts")
        const spacerElem = document.getElementById("global-alerts-spacer")
        if (alertsElem && spacerElem) {
            const height = alertsElem.offsetHeight
            spacerElem.style.height = height + "px" // eslint-disable-line
        }
    }

    /**
     * Template
     */
    return <div id="global-alerts-spacer" />
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default GlobalAlertsSpacer
