/**
 * Footer
 * -----------------------------------------------------------------------------
 */
import React, { useState } from "react"
import { Icon, Modal, Button } from "elements"
import Routes from "router"
import { useFeatures, useSelector, useDispatch, actions } from "store"
import { tagEvent } from "utils/analytics"
import Features from "@/utils/features"
import { getEnvironment } from "@/utils/env"
import CobrowseModalErrorNotLoaded from "../cobrowse/cobrowse_modal_error_not_loaded"
import CobrowseTermsModal from "../cobrowse/cobrowse_terms_modal"
import CobrowseEndedModal from "../cobrowse/cobrowse_session_ended_modal"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Footer: React.FC = () => {
    /**
     * Data from Store
     */
    const dispatch = useDispatch()
    const features = useSelector(state => state.app.features)
    const premiumPayInfo = useSelector(state => state.premiumPayInfo)
    const hsaInfo = useSelector(state => state.hsa)
    const account = useSelector(state => state.account)
    const cobrowse = useSelector(state => state.cobrowse)

    /**
     * Component State
     */
    const employerGroupCategory = account.profile.employerGroupCategory
    const [showModal, setShowModal] = useState(false)
    const [showCobrowseError, setShowCobrowseError] = useState(false)
    const [showCobrowseTerms, setShowCobrowseTerms] = useState(false)
    const [showCobrowseEnded, setShowCobrowseEnded] = useState(false)

    React.useEffect(() => {
        debugLog("cobrowse lastSessionEnd update:", cobrowse.lastSessionEnd)
        const { time, reason, notified } = cobrowse.lastSessionEnd
        if (time && !notified) {
            debugLog("show session ended modal, reason:", reason)
            setShowCobrowseEnded(true)

            // do this for the modal's onDismiss action:
            // dispatch(actions.receiveCobrowseSessionEndNotified())
        }
    }, [cobrowse.lastSessionEnd])

    React.useEffect(() => {
        // this value goes 'false' whenever the modal is dismissed
        if (!showCobrowseEnded) {
            dispatch(actions.receiveCobrowseSessionEndNotified())
        }
    }, [showCobrowseEnded])

    React.useEffect(() => {
        // close our modals if the cobrowse terms modal shows up -- otherwise, we end up in a focus trap
        if (cobrowse.isGlanceTermsModalShowing) {
            setShowCobrowseEnded(false)
            setShowCobrowseError(false)
            setShowCobrowseTerms(false)
        }
    }, [cobrowse.isGlanceTermsModalShowing])

    /**
     * Feature flags
     */
    const {
        [Features.PREMIUM_PAYMENT_ELIGIBLE]: premiumPaymentEligible,
        [Features.COBROWSE_AVAILABLE]: isCobrowseAvailable,
        [Features.JOINT_ADMIN_ENABLED]: isJointAdminEnabled,
        [Features.JOINT_ADMIN]: isJointAdmin,
        [Features.FOOTER_HINGE_HEALTH]: isHingeHealthFooter,
        [Features.FOOTER_SELF_GUIDED_EMOTIONAL_HEALTH]: isSelfGuidedEmotionalHealthFooter,
    } = useFeatures()

    /**
     * Disclaimer Companies
     */
    type DisclaimerCompany = {
        readonly name: string
        readonly paths: readonly string[]
        readonly condition: boolean // does this need to be a predicate function instead? if there's a timing issue evaluating vs displaying
    }
    /**
     * All potential companies to list in the BCBS brand disclaimer.
     */
    const disclaimerCompaniesComplete: ReadonlyArray<DisclaimerCompany> = [
        {
            name: "Doctor On Demand",
            paths: [
                Routes.findCare(),
                Routes.resources(),
                Routes.programsBehavioralHealth(),
                Routes.programsTelehealth(),
            ],
            condition: features.doctorOnDemand,
        },
        {
            name: "MDLIVE",
            paths: [
                Routes.findCare(),
                Routes.resources(),
                Routes.programsBehavioralHealth(),
                Routes.programsTelehealth(),
            ],
            condition: features.mdLiveVendor || features.mdLiveTelev,
        },
        {
            name: "InstaMed",
            paths: [Routes.home(), Routes.claimsAndCosts()],
            condition: !!(
                premiumPaymentEligible &&
                premiumPayInfo.premiumPayInfoItem?.dueDate
            ),
        },
        {
            name: "VSP",
            paths: [Routes.visionBenefits()],
            condition: features.coveredServicesFindCareVsp,
        },
        {
            name: "HealthEquity",
            paths: [Routes.claimsAndCosts()],
            condition: hsaInfo.showHSACard,
        },
        {
            name: "AbleTo",
            paths: [
                Routes.resources(),
                Routes.programsBehavioralHealth(),
                Routes.programsTelehealth(),
            ],
            condition: features[Features.FOOTER__ABLE_TO],
        },
        {
            name: "2nd.MD",
            paths: [Routes.resources(), Routes.programsTelehealth()],
            condition:
                features.expert2ndOpinion ||
                features[Features.RESOURCE_EXPERT_OPINION],
        },
        {
            name: "Hinge Health",
            paths: [Routes.resources(), Routes.programsBehavioralHealth()],
            condition: isHingeHealthFooter,
        },
        {
            name: "Self-guided emotional health",
            paths: [Routes.resources(), Routes.programsBehavioralHealth()],
            condition: isSelfGuidedEmotionalHealthFooter,
        },
        {
            name: "DispatchHealth",
            paths: [Routes.resources(), Routes.programsTelehealth()],
            condition: features[Features.DISPATCH_HEALTH],
        },
    ]

    /**
     * Get all company names that should appear in the BCBS brand disclaimer.
     * Determined by:
     * - current page
     * - current user (feature flag or other condition)
     * @returns company names
     */
    const getDisclaimerCompanies = (): readonly string[] => {
        const currentPath = window.location.pathname

        return disclaimerCompaniesComplete
            .filter(x => x.paths.includes(currentPath))
            .filter(x => x.condition)
            .map(x => x.name)
    }

    /**
     * Define template variables
     */
    const sellingAffiliate = useSelector(
        state => state.account.profile.sellingAffiliate,
    )?.toLowerCase()
    const brandCode = useSelector(state => state.account.plan.brandCode)
    const links = [
        { label: "Terms and privacy", href: Routes.termsAndPrivacy() },
        { label: "Ethics and compliance", href: Routes.ethicsAndCompliance() },
        { label: "Fraud and abuse", href: Routes.fraudAndAbuse() },
        { label: "Feedback", href: Routes.feedback() },
    ]
    const jointAdminFooterLinks = [
        { label: "Terms and privacy", href: Routes.termsAndPrivacy() },
        { label: "Ethics and compliance", href: Routes.ethicsAndCompliance() },
        { label: "Fraud and abuse", href: Routes.fraudAndAbuse() },
    ]
    const languageUrl = Routes.nonDiscrimination()
    const languages = [
        { label: "Non-discrimination", href: "" },
        { label: "Español", href: "#spanish" },
        { label: "繁體中文", href: "#chinese-traditional" },
        { label: "Tiếng Việt", href: "#vietnamese" },
        { label: "한국어", href: "#korean" },
        { label: "Русский", href: "#russian" },
        { label: "Tagalog", href: "#tagalog" },
        { label: "Українська", href: "#ukrainian" },
        { label: "ខ្មែរ", href: "#mon-khmer-cambodian" },
        { label: "日本語", href: "#japanese" },
        { label: "አማርኛ", href: "#amharic" },
        { label: "Oroomiffa", href: "#cushite-oromo" },
        { label: "العربية", href: "#arabic" },
        { label: "ਪੰਜਾਬੀ", href: "#punjabi" },
        { label: "Deutsch", href: "#german" },
        { label: "ພາສາລາວ", href: "#laotion" },
        { label: "...", href: "" },
    ]
    const disclaimerCompanies = getDisclaimerCompanies()

    const showDisclaimer = !!(brandCode === "BLU" && disclaimerCompanies.length)

    const env = getEnvironment()
    const isJADM = isJointAdminEnabled && isJointAdmin

    /**
     * Methods
     */
    const isDebug = () => !["uat", "prd"].includes(env)

    const debugLog = (...args) => {
        if (isDebug()) {
            // eslint-disable-next-line no-console
            console.log(...args)
        }
    }

    const onCobrowseClick = () => {
        debugLog("clicked cobrowse")
        // show error modal in error cases (TODO: separate ticket) - error log for now
        const showError = () => setShowCobrowseError(true)

        const showTerms = () => setShowCobrowseTerms(true)

        if (globalThis.GLANCE?.Cobrowse?.Visitor?.inSession()) {
            debugLog(
                "GLANCE cobrowse clicked, but there is already an active session",
            )
            return
        }

        if (!globalThis.GLANCE) {
            console.error(
                "GLANCE unavailable - popup blocker/unsupported browser?",
            )
            showError()
            return
        }

        if (!globalThis.GLANCE.Cobrowse) {
            console.error("GLANCE.Cobrowse unavailable")
            showError()
            return
        }

        if (!globalThis.GLANCE.Cobrowse.Visitor?.showTerms) {
            // Visitor or Visitor.showTerms undefined
            console.error("GLANCE Visitor unavailable - not initialized?")
            showError()
            return
        }

        // no errors -- show cobrowse prompts
        debugLog("GLANCE Cobrowse ready, starting")
        showTerms()
    }

    const renderFooterLink = (link, index) => (
        <li key={index} className="inline px-xxs first:pl-none last:pr-none">
            <a
                href={link.href}
                target="_blank"
                className="text-gray-300 font-normal antialiased"
            >
                {link.label}
            </a>
            <Icon type="external-link" size={16} className="ml-two" />
        </li>
    )

    /**
     * Template
     */
    return (
        <div className="text-sm text-gray-300 font-normal antialiased">
            {/* Brand disclaimers */}
            {showDisclaimer && (
                <div className="mt-xxs text-center">
                    <span>
                        {disclaimerCompanies.map(
                            (companyName, i) =>
                                `${companyName}${
                                    i !== disclaimerCompanies.length - 1
                                        ? ", "
                                        : " "
                                }`,
                        )}
                        {disclaimerCompanies.length > 1
                            ? "are separate companies that do "
                            : "is a separate company that does "}
                        not provide Blue Cross and Blue Shield products or
                        services, and
                        {disclaimerCompanies.length > 1 ? " are " : " is "}{" "}
                        solely responsible for
                        {disclaimerCompanies.length > 1
                            ? " their "
                            : " its "}{" "}
                        products or services.
                    </span>
                </div>
            )}

            {/* Links */}
            <ul className="list-none p-none mt-xs text-center">
                {/* Render Statement of relationship link for HCA */}
                {employerGroupCategory === "ump" && (
                    <div className="inline px-xxs first:pl-none">
                        <a
                            href="#"
                            className="text-gray-300 font-normal antialiased"
                            onClick={() => {
                                setShowModal(true)
                                tagEvent({
                                    tealium_event: "link",
                                    data_analytics_id:
                                        "statement-of-relationship-link",
                                    link_text: "Statement of relationship",
                                    link_url: "",
                                })
                            }}
                        >
                            Statement of relationship
                        </a>
                        <Modal
                            name="statement-of-relationship"
                            size="large"
                            isVisible={showModal}
                            onDismiss={() => setShowModal(false)}
                            noBorder={true}
                        >
                            <div>
                                <h3 className="pl-xs">
                                    Statement of relationship
                                </h3>
                                <Modal.Content>
                                    <p className="pl-xs leading-6 text-gray-200">
                                        Uniform Medical Plan (UMP) is a
                                        self-insured Preferred Provider
                                        Organization (PPO) medical plan offered
                                        through the Washington State Health Care
                                        Authority. UMP medical plans are
                                        administered by Regence BlueShield.
                                    </p>
                                </Modal.Content>
                                <Modal.Footer className="text-left pl-xs">
                                    <Button
                                        name={
                                            "statement-of-relationship-ok-button"
                                        }
                                        label="OK"
                                        variant="primary"
                                        onClick={() => setShowModal(false)}
                                    />
                                </Modal.Footer>
                            </div>
                        </Modal>
                    </div>
                )}

                {(isCobrowseAvailable && !isJADM) && (
                    <React.Fragment>
                        <CobrowseModalErrorNotLoaded
                            showModal={showCobrowseError}
                            setShowModal={setShowCobrowseError}
                        />
                        <CobrowseTermsModal
                            showModal={showCobrowseTerms}
                            setShowModal={setShowCobrowseTerms}
                        />
                        <CobrowseEndedModal
                            showModal={showCobrowseEnded}
                            setShowModal={setShowCobrowseEnded}
                            reason={cobrowse.lastSessionEnd?.reason}
                        />
                    </React.Fragment>
                )}

                {isJADM
                    ? jointAdminFooterLinks.map(renderFooterLink)
                    : links.map(renderFooterLink)}

                {(isCobrowseAvailable && !isJADM) && (
                    <li key={"cobrowse-share"} className="inline pl-xs">
                        {/* no-underline */}
                        <Button
                            className="text-gray-300 font-normal antialiased p-none underline"
                            name={"cobrowse-share-button"}
                            variant="link"
                            type="submit"
                            onClick={onCobrowseClick}
                        >
                            Cobrowse
                        </Button>
                    </li>
                )}
            </ul>

            {/* Languages */}
            <ul className="list-none p-none mt-xxs text-center break-all">
                {languages.map((language, index) => (
                    <li
                        key={index}
                        className="inline border-r border-gray-300 px-xxxs first:pl-none last:pr-none last:border-r-none"
                    >
                        <a
                            href={languageUrl + language.href}
                            target="_blank"
                            className="text-gray-300 font-normal antialiased"
                        >
                            {language.label}
                        </a>
                    </li>
                ))}
            </ul>

            {/* Copyright */}
            <div className="mt-xxs text-center">
                <span>
                    {" "}
                    &copy; {new Date().getFullYear()}{" "}
                    {copyright(sellingAffiliate, brandCode)}
                </span>
            </div>
        </div>
    )
}

/**
 * Copyright
 * -----------------------------------------------------------------------------
 */
export const copyright = (
    sellingAffiliate: string | undefined,
    brandCode: string | undefined,
) => {
    switch (brandCode) {
        case "ASUR":
            return " Asuris Northwest Health."
        case "BSH":
            return " BridgeSpan Health Company."
        case "BLU":
            switch (sellingAffiliate) {
                case "id":
                    return " Regence BlueShield of Idaho. Regence BlueShield of Idaho is an Independent Licensee of the Blue Cross and Blue Shield Association."
                case "or":
                    return " Regence BlueCross BlueShield of Oregon. Regence BlueCross BlueShield of Oregon is an Independent Licensee of the Blue Cross and Blue Shield Association."
                case "ut":
                    return " Regence BlueCross BlueShield of Utah. Regence BlueCross BlueShield of Utah is an Independent Licensee of the Blue Cross and Blue Shield Association."
                case "ww":
                case "wa":
                    return " Regence BlueShield. Regence BlueShield serves select counties in the state of Washington and is an Independent Licensee of the Blue Cross and Blue Shield Association."
                default:
                    return " Regence."
            }
    }
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Footer
