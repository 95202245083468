import { PharmacistInquirySlice } from "@/store/rx/pharmacist-inquiries/reducer"

/**
 * Session Storage utilities for rx
 */

// Clear out AAP session items
export const clearAapSession = () => {
    sessionStorage.removeItem("topic")
    sessionStorage.removeItem("currentStep")
    sessionStorage.removeItem("selectedSubjectID")
    sessionStorage.removeItem("selectedSubjectName")
    sessionStorage.removeItem("selectedMedications")
    sessionStorage.removeItem("additionalOTCMeds")
    sessionStorage.removeItem("selectedCondition")
    sessionStorage.removeItem("effectiveness")
    sessionStorage.removeItem("writeInCondition")
    sessionStorage.removeItem("additionalInfo")
    sessionStorage.removeItem("useUnencryptedEmail")
    sessionStorage.removeItem("email")
}

export const isNoMedsEntered = (
    pharmacistInquiry: PharmacistInquirySlice,
): boolean => {
    if (
        pharmacistInquiry.selectedMedications === undefined &&
        pharmacistInquiry.additionalOTCMeds === undefined
    ) {
        return true
    }
    if (
        pharmacistInquiry.additionalOTCMeds === "" &&
        pharmacistInquiry.selectedMedications === undefined
    ) {
        return true
    }
    if (
        pharmacistInquiry.additionalOTCMeds === "" &&
        pharmacistInquiry.selectedMedications.length === 0
    ) {
        return true
    }
    return false
}

export const writeInConditionString = (
    pharmacistInquiry: PharmacistInquirySlice,
): string => {
    if (pharmacistInquiry.writeInCondition) {
        return  pharmacistInquiry.writeInCondition
    }

    return "None selected"
}

export const selectedMedsString = (pharmacistInquiry:PharmacistInquirySlice):string => {
    if (isNoMedsEntered(pharmacistInquiry)) return "None selected"

    const selectedMeds = pharmacistInquiry.selectedMedications
    const otherMeds = pharmacistInquiry.additionalOTCMeds
    const joinedSelectedMeds = selectedMeds
        ?.map(med => med.displayName)
        .join(", ")

    if (otherMeds === undefined || otherMeds === "" || otherMeds === null) {
        return joinedSelectedMeds
    } else {
        if (joinedSelectedMeds === "" || joinedSelectedMeds === undefined) {
            return otherMeds
        } else {
            return `${joinedSelectedMeds}, ${otherMeds}`
        }
    }
}
