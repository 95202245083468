/**
 * Empty State
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Button, Pictogram } from "heartwood-component-library"
import { ContentBlock, FRAGMENT_IDS, ManagedContentStatus, fetchManagedContentMultiFragment } from "@/utils/styx"
import { useTranslation } from "react-i18next"
import { tagEvent } from "@/utils/analytics"
import router from "next/router"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly label?: string
    readonly className?: string
    readonly urlText?: string
    readonly url?: string
    readonly disclosureAllowed?: boolean
    readonly shimmer?: boolean
}
/**
 * Component
 * -----------------------------------------------------------------------------
 */
const EmptyState: React.FC<Props> = (props) => {
    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Component State
     */
    const [emptyStatus, setEmptyStatus] = React.useState<ManagedContentStatus>("pristine")
    const [unauthStatus, setUnauthStatus] = React.useState<ManagedContentStatus>("pristine")
    const [emptyFragment, setEmptyFragment] = React.useState<ReadonlyArray<ContentBlock>>([])
    const [unauthFragment, setUnauthFragment] = React.useState<ReadonlyArray<ContentBlock>>([])

    /**
     * Fetch empty state managed content fragments
    */
    const fragmentIDs = [
        FRAGMENT_IDS.PHARMACY_EMPTY_STATE,
        FRAGMENT_IDS.PHARMACY_UNAUTHORIZED_STATE,
    ]

    React.useEffect(() => {
        fetchManagedContentMultiFragment(fragmentIDs[0], setEmptyStatus, setEmptyFragment)
        fetchManagedContentMultiFragment(fragmentIDs[1], setUnauthStatus, setUnauthFragment)
    }, [])

     /**
     * Methods
     */
     const onClick = () => {
        router.push(props.url)

         tagEvent({
             tealium_event: "link",
             data_analytics_id: "empty-state-button-click",
             link_text: props.urlText,
             link_url: props.url
         })
     }

    /**
     * Define template variables
     */
    const auth = true // RGR-9658 ??? - props.disclosureAllowed === undefined || props.disclosureAllowed
    const shimmer = props.shimmer || emptyStatus == "loading" || unauthStatus == "loading"
    const emptyResource = auth ? emptyFragment?.[0] : unauthFragment?.[0]
    const strings = t("dashboard.emptyState", { returnObjects: true })
    const defaultLabel = emptyResource?.description || strings.default
    const description =  auth ? props.label || defaultLabel : defaultLabel
    const regencePictogram = emptyResource?.pictograms?.[0]?.regence || "reg_soup_gray"
    const asurisPictogram = emptyResource?.pictograms?.[0]?.asuris || "ahs_check_grey"
    const bshPictogram = emptyResource?.pictograms?.[0]?.bridgespan || "bsh_star"

    if (shimmer) {
        return <div />
    }

    return (
        <div className={`bg-white dark:bg-dark-200 rounded-sm py-md px-lg ${props.className}`}>
            <div className="text-center">
                <Pictogram
                    alt=""
                    regence={regencePictogram}
                    asuris={asurisPictogram}
                    bridgespan={bshPictogram}
                    className="mx-auto"
                    size="small"
                />
                <p className="text-muted mb-none mt-xs" >
                    {description}
                </p>
                {props.urlText?.length > 0 && (
                    <Button
                        name="empty-state-url"
                        label={props.urlText}
                        variant="link"
                        className="font-semibold px-xs"
                        onClick={onClick}
                    />
                )}
            </div>
        </div >
    )
}

/**
 * Export Component
 * -----------------------------------------------------------------------------
 */
export default EmptyState
