/**
 * Member Cards Utilities
 * -----------------------------------------------------------------------------
 *
 */
import { parseDate } from "utils/date"
import { differenceInDays} from "date-fns"

export const MEMBER_CARDS_PREV_URL_KEY = "MEMBER_CARDS_PREV_URL"

/**
 * Checks if member card can be ordered for user
 *
 */
export const isDisableOrderMemberCards = (orderDate: string): boolean => {
    return differenceInDays(new Date(), parseDate(orderDate)) < 2
}