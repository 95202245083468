/**
 * End Chat Modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions } from "store"
import { Modal, Button } from "elements"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const EndChatModal = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()

    /**
     * Application state
     */
    const chat = useSelector(state => state.chat)

    /**
     * Methods
     */
    const handleEndChatClick = () => {
        dispatch(actions.toggleEndChatModal())
        dispatch(actions.endChat())
        localStorage?.removeItem("CHAT_IS_VISIBLE")
    }
    const hideEndChatModal = () => {
        dispatch(actions.toggleEndChatModal())
    }

    /**
     * Define template variables
     */
    const isVisible = chat.showEndChatModal

    /**
     * Template
     */
    return (
        <Modal
            name={"end-chat"}
            title={"End chat session?"}
            size="small"
            noBorder={true}
            noDismiss={false}
            isVisible={isVisible}
            onDismiss={hideEndChatModal}
            doNotDismissOnBodyClick={true}
        >
            <Modal.Content>
                <p>
                    By ending the session your conversation with customer
                    support will be closed.
                </p>
            </Modal.Content>
            <Modal.Footer className="text-right">
                <Button
                    name={"end-chat-cancel-button"}
                    label="Cancel"
                    variant="link"
                    onClick={hideEndChatModal}
                />
                <Button
                    name={"end-chat-button"}
                    label="End chat"
                    variant="primary"
                    size="medium"
                    onClick={handleEndChatClick}
                />
            </Modal.Footer>
        </Modal>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default EndChatModal
