/**
 * Glance Cobrowse - error modal for when script not loaded
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Button, Modal } from "@/elements"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly showModal: boolean
    readonly setShowModal: (b: boolean) => void
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const CobrowseModalErrorNotLoaded = (props: Props) => {
    /**
     * Hooks
     */

    /**
     * Application state
     */

    /**
     * Component state
     */

    /**
     * Define template variables
     */

    /**
     * Methods
     */

    /**
     * Lifecycle
     */

    /**
     * Template
     */
    return (
        <React.Fragment>
            <Modal
                name="cobrowse-error-not-loaded"
                size="small"
                title="Cobrowse unavailable"
                isVisible={props.showModal}
                onDismiss={() => props.setShowModal(false)}
                noBorder={true}
                noDismiss={true}
            >
                <Modal.Content className="flex justify-center">
                    <div className="mb-sm">
                        We apologize, there may be an issue with your browser. Work with Customer Support to troubleshoot.
                    </div>
                </Modal.Content>
                <Modal.Footer className="text-right">
                    <Button
                        name="Dismiss modal"
                        label="Okay"
                        variant="primary"
                        onClick={() => props.setShowModal(false)}
                    />
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default CobrowseModalErrorNotLoaded
