/**
 * Chat
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions } from "store"
import Header from "./header"
import Conversation from "./conversation"
import Input from "./input"
import Toggle from "./toggle"
import EndChatModal from "./end_chat_modal"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly isVisible: boolean
    readonly setVisible: (value: boolean) => void
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Chat = (props: Props) => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()

    /**
     * Application state
     */
    const chat = useSelector(state => state.chat)

    /**
     * Component state
     */
    const chatIsVisible = localStorage?.getItem("CHAT_IS_VISIBLE")
    const isVisible = chatIsVisible === "true"

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (
            chat.isVisible &&
            chat.status === "pristine" &&
            chatIsVisible &&
            !isVisible)
        {
            // Minimized chat window
            dispatch(actions.toggleChat())
        }
        // Create and updating the local storage chat visible item
        if (
            chat.isVisible &&
            !chatIsVisible &&
            !chat.isChatEnded
        ) {
            localStorage?.setItem("CHAT_IS_VISIBLE", "true")
        } else if (
            chat.isVisible !== isVisible &&
            chat.status === "completed" &&
            !chat.isChatEnded
        ) {
            localStorage?.setItem(
                "CHAT_IS_VISIBLE",
                chat.isVisible ? "true" : "false",
            )
        }
    }, [chat.isVisible])

    React.useEffect(() => {
        // Trigger showing chat window after page refresh
        if (
            chatIsVisible &&
            !chat.isRefresh &&
            chat.status === "pristine" &&
            !chat.isVisible
        ) {
            dispatch(actions.toggleChat())
            dispatch(actions.toggleIsRefresh())
        } else if (
            !chatIsVisible &&
            chat.isRefresh &&
            chat.isChatEnded
        ) {
            dispatch(actions.toggleIsRefresh())
        }
    }, [chatIsVisible])

    /**
     * Define template variables
     */
    // TODO: Replace with display rule to show chat toggle
    const showToggle = chat.status !== "pristine" || chat.isVisible

    /**
     * Styles
     */
    // prettier-ignore
    const styles = {
        wrap: "fixed z-header bottom-none right-none h-full w-full md:w-[400px] flex flex-col justify-end pointer-events-none",
        inner: "flex-1 h-full md:max-h-[575px] px-xs pt-xxxl min-h-0",
        hidden: props.isVisible ? "" : "hidden",
        content: "flex flex-col h-full rounded shadow pointer-events-auto",
        toggle: "p-xs",
    }

    /**
     * Template
     */
    return (
        <React.Fragment>
            <div className={styles.wrap}>
                {/* Chat Window */}
                <div className={`${styles.inner} ${styles.hidden}`}>
                    <div className={styles.content}>
                        <Header />
                        <Conversation className="flex-1 min-h-0" />
                        <Input />
                    </div>
                </div>

                {/* Toggle Button */}
                {showToggle && (
                    <div className={styles.toggle}>
                        <Toggle />
                    </div>
                )}
            </div>

            {/* End chat modal */}
            <EndChatModal />
        </React.Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Chat
