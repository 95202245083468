/**
 * Brand Logo
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector } from "store"

/**
 * Logo Assets
 * -----------------------------------------------------------------------------
 */
const Asuris = {
    default: "/assets/img/logos/asuris.svg",
    alt: "Asuris logo",
}
const Bridgespan = {
    default: "/assets/img/logos/bridgespan.svg",
    alt: "Bridgespan logo",
}
const RegenceRegions = {
    ww: {
        default: "/assets/img/logos/regence_bs.svg",
        alt: "Regence BlueShield logo",
    },
    wa: {
        default: "/assets/img/logos/regence_bs.svg",
        alt: "Regence BlueShield logo",
    },
    id: {
        default: "/assets/img/logos/regence_bs.svg",
        alt: "Regence BlueShield logo",
    },
    or: {
        default: "/assets/img/logos/regence_bcbs.svg",
        alt: "Regence BlueCross BlueShield logo",
    },
    ut: {
        default: "/assets/img/logos/regence_bcbs.svg",
        alt: "Regence BlueCross BlueShield logo",
    },
    default: {
        default: "/assets/img/logos/regence.svg",
        alt: "Regence logo",
    },
}

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly brand?: "regence" | "asuris" | "bridgespan"
    readonly className?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const BrandLogo = (props: Props) => {
    /**
     * Application state
     */
    const { brand } = useSelector(state => state.app)
    const { sellingAffiliate } = useSelector(state => state.account.profile)

    /**
     * Define template variables
     */
    const region = sellingAffiliate?.toLowerCase() || "default"
    const regence = RegenceRegions[region] || {}

    /**
     * Template: Asuris
     */
    if ((props.brand || brand) === "asuris") {
        return (
            <img
                src={Asuris.default}
                alt={Asuris.alt}
                className={props.className || ""}
            />
        )
    }

    /**
     * Template: Bridgespan
     */
    if ((props.brand || brand) === "bridgespan") {
        return (
            <img
                src={Bridgespan.default}
                alt={Bridgespan.alt}
                className={props.className || ""}
            />
        )
    }

    /**
     * Template: Regence
     */
    return (
        <img
            src={regence.default}
            alt={regence.alt}
            className={props.className || ""}
        />
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default BrandLogo
