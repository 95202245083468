/**
 * Chat Toggle Button
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions } from "store"
import { Button } from "elements"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ChatToggle = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()

    /**
     * Application state
     */
    const { chat } = useSelector(state => state)

    /**
     * Template
     */
    return (
        <div className={`text-right`}>
            <Button
                name="chat-toggle"
                label={chat.isVisible ? undefined : "Customer chat"}
                labelClass="text-xl -mt-two"
                icon={chat.isVisible ? "angle-down-md" : "chat"}
                size="large"
                rounded={true}
                className="shadow-lg pointer-events-auto"
                onClick={() => dispatch(actions.toggleChat())}
            />
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ChatToggle
