/**
 * Patient Initials Utilities
 * -----------------------------------------------------------------------------
 */
import { Member } from "@/store/insights/types"
import { formatCase } from "heartwood-component-library"
import { useSelector } from "store"

/**
 * Show Patient Initials
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showPatientInitials = (members: ReadonlyArray<any>, id?: string) => {
    const app = useSelector(state => state.app)
    const brand = app.brand
    const isBSH = brand === "bridgespan"
    const hasFamily = members?.length > 1

    if (id) {
        const patient = members.find(member => member.membershipId === id)
        const hasFirstName = patient?.firstName?.length > 0
        return hasFirstName && hasFamily && !isBSH
    } else {
        return hasFamily && !isBSH
    }
}
    
/**
 * Patient Initials
 */
export const patientFirstName = (members: ReadonlyArray<Member>, id: string) => {
    const patient = members?.find(member => member.membershipId === id)
    return formatCase(patient?.firstName, "titlecase")
}
