/**
 * Application Layout
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useRouter } from "next/router"
import { useSelector, useDispatch, actions, useFeatures } from "store"
import Features from "@/utils/features"
import { formatCase } from "utils"
import { Props as ButtonProps } from "elements/button"
import Head from "next/head"
import Header from "./header"
import Footer from "./footer"
import Navigation from "./navigation"
import GlobalAlerts from "./global-alerts"
import GlobalAlertsSpacer from "./global-alerts-spacer"
import Chat from "components/chat"
import GlanceIncludeScript from "../cobrowse/glance_include_script"
import Dynamic from "next/dynamic"
const Pager = Dynamic(() => import("components/pager"), { ssr: false })

const HEADLESS_SESSION_COOKIE_NAME = "dfwHeadlessContent"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly children: React.ReactNode
    readonly title?: string

    /**
     * Hide sections
     */
    readonly hideHeader?: boolean
    readonly hideNavigation?: boolean
    readonly hideFooter?: boolean

    /**
     * Section variants
     */
    readonly headerVariant?: "default" | "simple"
    readonly headerButtonLeft?: ButtonProps
    readonly headerButtonRight?: ButtonProps
    readonly headerProgress?: number
    readonly headerDisableShadow?: boolean
    readonly isNoLogoLink?: boolean

    /**
     * Style overrides
     */
    readonly noPadding?: boolean
    readonly contentClass?: string

    /**
     * Event handlers
     */
    readonly handleResize?: () => void
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Layout: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const router = useRouter()

    /**
     * Feature Toggle
     */
    const {
        [Features.JOINT_ADMIN_ENABLED]: isJointAdminEnabled,
        [Features.JOINT_ADMIN]: isJointAdmin,
        [Features.COBROWSE_AVAILABLE]: isCobrowseAvailable,
        [Features.NURSE_CHAT]: nurseChat,
    } = useFeatures()

    const isHeadlessContent = () => {
        if (router.query.headless) {
            sessionStorage.setItem(HEADLESS_SESSION_COOKIE_NAME, "true")
        }
        return (
            router.query.headless ||
            sessionStorage.getItem(HEADLESS_SESSION_COOKIE_NAME)
        )
    }

    React.useEffect(() => {
        if (props.handleResize) {
            window.addEventListener("resize", props.handleResize)
        }
    }, [])

    /**
     * Application state
     */
    const brand = useSelector(state => state.app.brand)
    const chat = useSelector(state => state.chat)
    const pageTitle = brand ? formatCase(brand, "capitalize") : ""

    /**
     * Component state
     */
    const [navigation, showNavigation] = React.useState(false);
    /**
     * Methods
     */
    const dispatch = useDispatch()
    const toggleChat = () => dispatch(actions.toggleChat())

    /**
     * Define template variables
     */
    const contentClass = props.contentClass || "content"
    const faviconHref =
        brand === "regence"
            ? `/assets/favicons/regence/favicon.ico`
            : `/assets/favicons/${brand}/favicon.ico`
    const isJADM = (isJointAdminEnabled && isJointAdmin)

    /**
     * Headless template
     */
    if (isHeadlessContent()) {
        return (
            <div id="headlessContent" className={contentClass}>
                {props.children}
            </div>
        )
    }

    /**
     * Template
     */
    return (
        <React.Fragment>
            <Head>
                <title>
                    {props.title}
                    {props.title && pageTitle && " | "}
                    {pageTitle}
                </title>
                <link rel="shortcut icon" href={faviconHref} />
            </Head>

            {/* Chat */}
            <Chat isVisible={chat.isVisible} setVisible={() => toggleChat()} />
            {/* Pager chat*/}
            {nurseChat && <Pager />}

            {/* Header */}
            {!props.hideHeader && (
                <Header
                    showNavigation={showNavigation}
                    variant={props.headerVariant}
                    contentClass={contentClass}
                    buttonLeft={props.headerButtonLeft}
                    buttonRight={props.headerButtonRight}
                    progress={props.headerProgress}
                    isNoLogoLink={props.isNoLogoLink}
                    disableShadow={props.headerDisableShadow}
                />
            )}
            <div className="flex min-h-screen">
                {/* Navigation */}
                {!props.hideNavigation && !isJADM && (
                    <Navigation
                        isVisible={navigation}
                        showNavigation={showNavigation}
                    />
                )}
                <div className={`flex-1 min-w-[0px]`}>
                    <div className="flex flex-col min-h-full">
                        {/* Content */}
                        <div className="flex-1">
                            <div
                                className={props.noPadding ? "" : contentClass}
                            >
                                {props.children}
                            </div>
                        </div>

                        {/* Footer */}
                        {!props.hideFooter && (
                            <div>
                                <div className={contentClass}>
                                    <Footer />
                                </div>
                            </div>
                        )}

                        {/* Spacer */}
                        <GlobalAlertsSpacer />
                    </div>
                </div>
            </div>

            {/* Global alerts (non-error alerts) */}
            <div className="fixed bottom-none w-full z-toast shadow-lg">
                <GlobalAlerts position="footer" />
            </div>

            {(isCobrowseAvailable && !isJADM) && (<GlanceIncludeScript />)}
        </React.Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Layout
