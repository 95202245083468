/**
 * Navigation
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { TFuncKey } from "i18next"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import Routes from "router"
import { Link, Icon, IconTypes, Button } from "elements"
import AccountDropdown from "./account_dropdown"
import Features from "@/utils/features"
import { useFeatures } from "@/store"
import { Features as FeaturesProps } from "@/store/app/types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly isVisible: boolean
    readonly showNavigation: (value: boolean) => void
}

interface NavItemChild {
    readonly string_key: TFuncKey
    readonly name: string
    readonly active?: boolean
    readonly path?: string
    readonly target?: string
    readonly enabled?: boolean
    readonly tabs?: ReadonlyArray<string>
}
interface NavItem {
    readonly string_key: TFuncKey
    readonly name: string
    readonly path?: string
    readonly icon?: IconTypes
    readonly enabled?: boolean
    readonly children?: ReadonlyArray<NavItemChild>
    readonly tabs?: ReadonlyArray<string>
}

/**
 * Navigation Item
 * -----------------------------------------------------------------------------
 */
const NavigationConfiguration = ({
    features,
}: {
    readonly features: FeaturesProps
}): ReadonlyArray<NavItem> => [
    {
        string_key: "navigation.home",
        name: "home-link",
        icon: "home",
        path: Routes.home(),
        enabled: features[Features.NAV__HOME],
    },
    {
        string_key: "navigation.claims",
        name: "claims-link",
        icon: "person-family",
        path: Routes.claims(),
        enabled: features[Features.NAV__CLAIMS_COSTS__CLAIMS],
        tabs: ["/claims-and-costs/claims"],
    },
    {
        string_key: "navigation.coverage",
        name: "coverage-link",
        icon: "briefcase",
        path: Routes.checkBenefits(),
        enabled: features[Features.NAV__COVERED_SERVICES],
        tabs: ["/covered-services/check-benefits/medical"],
        children: [
            {
                string_key: "navigation.yourBenefits",
                name: "your-benefits-link",
                path: Routes.checkBenefits(),
                enabled: features[Features.NAV__COVERED_SERVICES__CHECK_BENEFITS],
                tabs: [
                    "your-benefits/dental",
                    "your-benefits/vision",
                    "your-benefits/pharmacy",
                    "your-benefits/hearing",
                    "/covered-services/check-benefits/medical",
                    "/covered-services/check-benefits/dental",
                    "/covered-services/check-benefits/vision",
                    "/covered-services/check-benefits/pharmacy",
                    "/covered-services/check-benefits/hearing",
                ],
            },
            {
                string_key: "navigation.yourEOBs",
                name: "your-eobs-link",
                path: Routes.eobs(),
                enabled: features[Features.NAV__CLAIMS_COSTS__EOB_STATEMENTS],
                tabs: ["/claims-and-costs/eob-statements"],
            },
        ],
    },
    {
        string_key: "navigation.costs",
        name: "costs-link",
        icon: "money-circle",
        path: Routes.claimsAndCosts(),
        enabled: features[Features.NAV__CLAIMS_COSTS],
        tabs: ["/claims-and-costs/overview"],
        children: [
            {
                string_key: "navigation.overview",
                name: "overview-link",
                path: Routes.claimsAndCosts(),
                enabled: features[Features.NAV__CLAIMS_COSTS__OVERVIEW],
                tabs: ["/claims-and-costs/overview"],
            },
            {
                string_key: "navigation.estimateCosts",
                name: "estimate-costs-link",
                path: Routes.costEstimates(),
                enabled: features[Features.NAV__CLAIMS_COSTS__ESTIMATE_COSTS],
                tabs: ["/claims-and-costs/estimate-costs"],
            },
            {
                string_key: "navigation.payments",
                name: "payments-link",
                path: Routes.payments(),
                enabled:
                    features[Features.NAV__CLAIMS_COSTS__PAYMENTS] &&
                    !features[Features.CLAIMS_COSTS__PAYMENTS__ALERT__SSA],
                tabs: ["/claims-and-costs/payments"],
            },
        ],
    },
    {
        string_key: "navigation.care",
        name: "care-link",
        icon: "heart",
        path: Routes.timeline(),
        enabled: features[Features.NAV__YOUR_CARE],
        tabs: ["/your-care/timeline"],
        children: [
            {
                string_key: "navigation.findCare",
                name: "find-care-link",
                path: Routes.findCare(),
                enabled: features[Features.NAV__COVERED_SERVICES__FIND_CARE],
                tabs: ["/covered-services/find-care"],
            },
            {
                string_key: "navigation.yourCareHistory",
                name: "your-care-link",
                path: Routes.timeline(),
                enabled: features[Features.NAV__YOUR_CARE__TIMELINE],
                tabs: ["/your-care/timeline"],
            },
            {
                string_key: "navigation.yourProviders",
                name: "your-providers-link",
                path: Routes.providers(),
                enabled: features[Features.NAV__YOUR_CARE__DOCTORS_AND_PROVIDERS],
                tabs: ["/your-care/doctors-and-providers"],
            },
            {
                string_key: "navigation.careResources",
                name: "care-resources-link",
                path: Routes.resources(),
                enabled: features[Features.NAV__COVERED_SERVICES__RESOURCES],
                tabs: [
                    "medicare",
                    "behavioral-health",
                    "condition-management",
                    "maternity",
                    "telehealth",
                    "/covered-services/resources",
                ],
            },
        ],
    },
    {
        string_key: "navigation.pharmacy",
        name: "pharmacy-link",
        icon: "pills",
        path: Routes.medications(),
        enabled: features[Features.NAV__YOUR_CARE__MEDICATIONS],
        tabs: ["/your-care/medications"],
    },
    {
        string_key: "navigation.documents",
        name: "documents-link",
        icon: "document",
        path: Routes.forms(),
        enabled: features[Features.NAV__FORMS_DOCUMENTS],
        tabs: ["/covered-services/forms-and-documents"],
        children: [
            {
                string_key: "navigation.formsDocuments",
                name: "forms-documents-link",
                path: Routes.forms(),
                enabled: features[Features.NAV__FORMS_DOCUMENTS],
                tabs: ["/covered-services/forms-and-documents"],
            },
            {
                string_key: "navigation.taxInformation",
                name: "tax-information-link",
                path: Routes.taxInformation(),
                enabled: features[Features.NAV__CLAIMS_COSTS__TAX_FORMS],
                tabs: ["/claims-and-costs/tax-information"],
            },
        ],
    },
    {
        string_key: "navigation.contactUs",
        name: "contact-us-link",
        icon: "chat",
        path: Routes.contactUs(),
        enabled: features[Features.NAV__SUPPORT],
        tabs: [
            "faqs",
            "/support",
            "/support/faqs"
        ],
    },
]

/**
 * Filter `NavigationConfiguration` based on feature flags
 */
const useNavigationItems = (): ReadonlyArray<NavItem> => {
    const features = useFeatures()
    return NavigationConfiguration({ features }).reduce((nav, item) => {
        if (item.children) {
            const children = item.children.filter(child => {
                return child.enabled
            })

            // if a nav item parent has no children after applying feature flags,
            // do not include the parent
            if (children.length) {
                // item.path = children[0].path
                nav.push({ ...item, children, path: children[0].path })
            }
        } else {
            if (item.enabled) {
                nav.push(item)
            }
        }

        return nav
    }, [])
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Navigation: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const navigationItems = useNavigationItems()
    const { t } = useTranslation()

    const { [Features.DASHBOARD_LANGUAGE_SPANISH]: showSpanishDashboard } =
        useFeatures()

    /**
     * Define template variables
     */
    const { isVisible } = props

    /**
     * Template
     */
    return (
        <React.Fragment>
            {/* Mask */}
            <div
                data-type="navigation-show"
                onClick={() => props.showNavigation(false)}
                className={`bg-black bg-opacity-70 h-full w-full fixed top-none z-header transition-opacity duration-300 lgx:hidden ${
                    isVisible ? "" : "opacity-0 pointer-events-none"
                }`}
            />

            <div className="w-none lgx:w-[240px] relative sm:z-header lgx:z-sidebar">
                <div
                    className={`fixed bg-primary h-full w-full md:w-[280px] lgx:w-[240px] transform transition-transform duration-300 ${
                        isVisible
                            ? ""
                            : "-translate-x-full lgx:-translate-x-none"
                    }`}
                >
                    {/* Note: overflow is unreliable without fixed header height */}
                    <div id="nav-items-wrap" className="max-h-screen overflow-y-auto">
                        {/* Close button */}
                        <Button
                            data-type="navigation-close-button"
                            name="dismiss-navigation-button"
                            icon="times"
                            onClick={() => props.showNavigation(false)}
                            className="mx-xs mt-xs lgx:hidden"
                        />
                        {showSpanishDashboard && (
                            <div className="m-xs lgx:hidden">
                                <Button
                                    data-type="navigation-spanish-button"
                                    name="navigation-spanish-dashboard-button"
                                    label={t("navigation.inSpanish")}
                                    inverted={true}
                                    variant="secondary"
                                    className="df-track w-full"
                                    href={Routes.dashboardES()}
                                />
                            </div>
                        )}

                        {/* Find Care */}
                        <div className="m-xs lgx:hidden">
                            <Button
                                data-type="navigation-find-care-button"
                                name="find-care-button"
                                label={t("navigation.findCare")}
                                inverted={true}
                                href={Routes.findCare()}
                                className="df-track w-full"
                                isExternal={false}
                            />
                        </div>

                        {/* Mobile account dropdown */}
                        <div className="md:hidden">
                            <AccountDropdown
                                data-type="navigation-account-dropdown"
                                name="account-accordion"
                                layout="accordion"
                            />
                        </div>
                        <div className="pb-lg mb-lg">
                            {/* Navigation items */}
                            {navigationItems.map((item, index) => (
                                <NavigationItem key={index} {...item} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

/**
 * Subcomponent: Navigation Item
 * -----------------------------------------------------------------------------
 */

export const NavigationItem: React.FC<NavItem> = props => {
    /**
     * Hooks
     */
    const router = useRouter()
    const path = router.pathname
    const { t } = useTranslation()
    const newNav = "nav-"

    /**
     * Styles
     */
    const btnClasses =
        "flex items-center w-full h-xxl px-sm text-white no-underline hover:bg-primary-300 dark:hover:bg-dark-200 hover:opacity-100"
    const activeClass = "bg-primary-300 dark:bg-dark-200"

    /**
     * Define template variables
     */
    const isChildActive = props.children?.some(r => r.path === path)
    const isSubChildActive =
        3 <= path.split("/").length &&
        props.children?.some(r => r.path === "/" + path.split("/")[1])

    const findActiveTabs = r => {
        return (
            r.tabs &&
            0 < r.tabs.length &&
            r.tabs.some(
                tabName =>
                    path == tabName ||
                    path == r.path + "/" + tabName ||
                    path == "/" + r.path.split("/")[1] + "/" + tabName,
            )
        )
    }

    const isSubChildTabActive =
        3 <= path.split("/").length &&
        (findActiveTabs(props) || props.children?.some(r => findActiveTabs(r)))

    const isActive =
        props.path === path ||
        findActiveTabs(props) ||
        isChildActive ||
        isSubChildActive ||
        isSubChildTabActive

    /**
     * Template
     */
    return (
        <React.Fragment>
            {/* Parent */}
            <Link
                id={props.name}
                name={props.name}
                data-type={ `${newNav}${t(props.string_key)}-link` }
                analytics={{ link_text: `${newNav} + ${t(props.string_key)}` }}
                href={props.path}
                aria-label={t(props.string_key) as string}
                unstyled={true}
                className={"df-track"}
            >
                <div className={`${btnClasses} ${isActive ? activeClass : ""}`}>
                    {props.icon && (
                        <Icon
                            type={props.icon}
                            className="mr-xs"
                            ariaLabel={t(props.string_key) as string}
                        />
                    )}
                    <>{t(props.string_key)}</>
                </div>
            </Link>

            {/* Children */}
            {isActive &&
                props.children?.map((child, index) => {
                    const showActiveClass1 = child.path === path
                    const showActiveClass2 =
                        3 <= path.split("/").length &&
                        child.path === "/" + path.split("/")[1]
                    const showActiveClass3 =
                        3 <= path.split("/").length &&
                        child.tabs &&
                        child.tabs.some(
                            tabName =>
                                path == tabName ||
                                path == child.path + "/" + tabName ||
                                path ==
                                    "/" +
                                        child.path.split("/")[1] +
                                        "/" +
                                        tabName,
                        )
                    const iframe = document.getElementById(
                        "iframeElement",
                    ) as HTMLIFrameElement

                    return (
                        <Link
                            key={index}
                            name={child.name}
                            data-type={ `${newNav}${t(child.string_key)}-link` }
                            analytics={{ link_text: `${newNav} + ${t(child.string_key)}` }}
                            href={child.path}
                            onClick={() =>
                                iframe?.contentWindow &&
                                iframe.contentWindow.window.location.reload()
                            }
                            target={child.target}
                            aria-label={t(child.string_key) as string}
                            unstyled={true}
                            className={"df-track"}
                        >
                            <div
                                className={`${btnClasses} ${
                                    showActiveClass1 ||
                                    showActiveClass2 ||
                                    showActiveClass3
                                        ? activeClass
                                        : ""
                                }`}
                            >
                                <div className="w-sm mr-xs" /> {/* Spacer */}
                                <>{t(child.string_key)}</>
                            </div>
                        </Link>
                    )
                })}
        </React.Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Navigation