/**
 * Insights Popover
 * -----------------------------------------------------------------------------
 * Built on: https://floating-ui.com
 */
import React, { useRef, useState } from "react"
import {
    useSelector,
    useDispatch,
    actions,
    useFeatureByName,
    useFeatures,
} from "store"
import { Avatar, AvatarStack, Button, Badge, Card, Clickable, Icon, IconTypes, Shimmer } from "heartwood-component-library"
import EmptyState from "components/dashboard/empty_state"
import router, { useRouter } from "next/router"
import Routes from "router"
import { insightAnalytics, tagEvent } from "utils/analytics"
import Features from "@/utils/features"
import {
    useFloating,
    arrow,
    autoUpdate,
    offset,
    shift,
    useDismiss,
    useRole,
    useInteractions,
    FloatingFocusManager,
    FloatingArrow,
} from "@floating-ui/react"
import { Insight, InsightAction } from "@/store/insights/types"
import { InsightProps } from "../props/insight_props"
import { campaignNonRx, currentInsights, isCampaign, publishedCampaign, showInsightItems } from "@/utils/rx/utilities"
import { patientFirstName, showPatientInitials } from "@/utils/patient_initials"
import { Campaigns } from "@/store/rx/campaigns/reducer"

/**
 * Types
 * -----------------------------------------------------------------------------
 */

interface InsightCampaignProps {
    readonly insightId: string
    readonly type: string
    readonly patientInitials: string
    readonly title: string
    readonly subtitle: string
    readonly analyticsTitle: string
    readonly category: string
    readonly icon: IconTypes
    readonly action: InsightAction
    readonly showInitials?: boolean
    readonly initials?: string
    readonly onClick?: (href?: string) => void
    readonly membershipId?: string
    readonly isNew: boolean
}

interface InsightCardProps {
    readonly insight: Insight
    readonly showInitials: boolean
    readonly initials: string
    readonly onClick?: (href?: string) => void
    readonly membershipId?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */

const InsightsPopover: React.FC <{
    readonly campaigns: Campaigns
}> = props => {

    const {
        [Features.HEADER_INSIGHTS_POPOVER_RX_ENHANCED]: rxEnhanced,
        [Features.RX_PCOMP_03]: showLinkToInsights,
    } = useFeatures()

    /**
     * Application state
     */
    const dispatch = useDispatch()
    const [insights, account] = useSelector(state => [
        state.insights,
        state.account,
    ])

    const [isOpen, setIsOpen] = useState(false)
    const [insightsEventsCompleted, setInsightsEventsCompleted] =
        React.useState(false)

    /**
     * Methods
     */
    const insightInitials = (id: string) => {
        return patientFirstName(insights?.members, id)
    }
    
    /**
     * Component state
     */
    const showInitials = showPatientInitials(insights.members)
    const memberId = account?.profile?.id
    const memberInitials = insightInitials(memberId)

    /**
     * New Insights Items first
     */
    const insightItems = showInsightItems(insights)
    const viewableInsights = insightItems.filter(i => publishedCampaign(i, props.campaigns))
    const newInsightsItems = viewableInsights?.filter(x => x.isNew)?.map(x => x)
    const newInsightsSize = newInsightsItems?.length
    const oldInsightsItems = viewableInsights?.filter(x => !x.isNew)?.map(x => x)
    const allInsightItems = newInsightsItems.concat(oldInsightsItems)
    const currentInsightItems = currentInsights(allInsightItems)
    const selectedInsightItems = showLinkToInsights ? currentInsightItems.slice(0,3) : currentInsightItems
    const selectedInsightsSize = selectedInsightItems?.length

    const pendingInsights = insights.status === "pending"
    const completedInsights = insights.status === "completed"
    const noNewInsights = newInsightsSize > 0
    const noInsightStyles = !pendingInsights && noNewInsights ? "hidden lgx:block" : "hidden lgx:block mr-xs"
    const label = `View all (${currentInsightItems?.length})`


    // Using this state flag to only send tealium events once for insight cards rendering view
    if (
        insights.status === "completed" &&
        !insightsEventsCompleted &&
        typeof globalThis?.utag !== "undefined" &&
        globalThis?.utag !== null
    ) {
        setInsightsEventsCompleted(true)
    }

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (insights.status === "pristine") {
            dispatch(actions.fetchInsights(showLinkToInsights))
        }
    }, [insights.status])

    const onIsOpen = (openState) => {
        setIsOpen(openState)

        tagEvent({
            tealium_event: "appcard_view",
            page_title: document.title,
            appcard_name: `set_is_open_${openState}`,
        })

        // if (!openState) {
        //     newInsightsItems.forEach(insight => {
        //         const analyticId = insight.analyticsTitle
        //             ? insight.analyticsTitle.replace(/ /g, "_")
        //             : insight.insightId
        //         onIsOpenClick(analyticId, insight)
        //     })
        // }
        // resets the Insights bell count using useEffect on insights.status change in actions
    }

    // const onIsOpenClick = (insight: InsightProps) => {
    //    tagEvent({
    //        tealium_event: "appcard_click",
    //        page_title: document.title,
    //        appcard_name: `${analyticId}_popover_is_open_click_${title}`,
    //        rx_campaign_id: campaignGuid,
    //    })
    //    dispatch(actions.clickInsight(insight))
    // }

    const onInsightCardView = (insight: InsightProps) => {
        const analytic = insightAnalytics(insight)

        tagEvent({
            tealium_event: "appcard_view",
            page_title: document.title,
            appcard_name: `${analytic.analyticId}_popover_insight_card_view_${analytic.title}`,
            rx_campaign_id: analytic.campaignGuid,
        })
    }

    const onInsightCardClick = (insight: InsightProps, href: string) => {
        const analytic = insightAnalytics(insight)

        tagEvent({
            tealium_event: "appcard_click",
            page_title: document.title,
            appcard_name: `${analytic.analyticId}_popover_insight_card_click_${analytic.title}`,
            rx_campaign_id: analytic.campaignGuid,
        })
        dispatch(actions.clickInsight(insight))
        // reset the Insights bell count
        onIsOpen(false)

        router.push(href)
    }

    const onDismiss = () => {
        // reset the Insights bell count
        onIsOpen(false)
    }

    const getMembershipId = (id: string) => {
        const family = insights.members?.find(member => member.membershipId === id)
        return family && family.membershipId ? family.membershipId : ""
    }

    const onLinkClick = () => {
        // eslint-disable-next-line functional/immutable-data
        router.push({
            pathname: Routes.insights(),
            query: { start: "bell" }
        })
    }

    /**
     * Define Popover variables
     */
    // const isMobile = window.matchMedia("(max-width: 600px)").matches
    const arrowRef = useRef(null)
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: onIsOpen,
        middleware: [
            offset(30),
            shift(),
            arrow({
                element: arrowRef,
            })
        ],
        whileElementsMounted: autoUpdate,
    })

    const dismiss = useDismiss(context)
    const role = useRole(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
        dismiss,
        role
    ])

    return (
        <React.Fragment>
            <Clickable
                name="header-insights-button"
                onClick={() => onIsOpen(completedInsights ? true : false)}
                className="text-primary-200 flex justify-between items-center"
            >
                <div className="relative mr-xs lgx:mr-xxs"
                    ref={refs.setReference}
                    {...getReferenceProps()}
                >
                    {noNewInsights && (
                        <Badge className="absolute top-one right-xxxs" />
                    )}
                    <Icon type="bell" />
                </div>
                <strong className={noInsightStyles}>Insights</strong>
                {pendingInsights && (
                    <div className="hidden lgx:block">
                        <Shimmer placeholder="99" />
                    </div>
                )}
                {!pendingInsights && noNewInsights && (
                    <Badge
                        label={newInsightsSize.toString()}
                        className="hidden lgx:block ml-xxs bg-primary-200"
                    />
                )}
            </Clickable>

            {isOpen && (
                <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                    <FloatingArrow
                        ref={arrowRef}
                        context={context}
                        fill="white"
                        width={20}
                        height={10}
                        tipRadius={2}
                    />
                    <FloatingFocusManager context={context} modal={false}>
                        <Card
                            name="insights-popover"
                            className="text-left max-w-[557px] px-xs py-xxs lgx:px-sm lgx:py-sm"
                        >
                            <div className="flex justify-between items-center">
                                <h5 className="mb-none">Your insights</h5>
                                <Button
                                    name="insights-popover-dismiss-button"
                                    icon="x"
                                    variant="link"
                                    onClick={() => onDismiss()}
                                    className="order-1 lgx:order-2"
                                />
                            </div>

                            {/* Insights completed */}
                            {completedInsights && selectedInsightsSize > 0 && (
                                <div className="relative mt-xxs lgx:mt-xs">
                                    <ul className="list-none pl-none pb-xs pr-xxs lgx:pr-xs max-h-[250px] overflow-y-auto">
                                        {selectedInsightItems.map((insight, index) => {
                                            if (!insightsEventsCompleted)
                                                onInsightCardView(insight)
                                            if (isCampaign(insight)) {
                                                return (
                                                    <CampaignItem
                                                        key={index}
                                                        {...insight}
                                                        showInitials={showInitials}
                                                        initials={insightInitials(insight.member.membershipId)}
                                                        onClick={(href: string) =>
                                                            onInsightCardClick(insight, href)
                                                        }
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <InsightItem
                                                        key={index}
                                                        insight={insight}
                                                        showInitials={showInitials}
                                                        initials={
                                                            insightInitials(insight.member.membershipId) ||
                                                            memberInitials
                                                        }
                                                        membershipId={
                                                            getMembershipId(insight.member.membershipId) || ""
                                                        }
                                                        onClick={(href: string) =>
                                                            onInsightCardClick(insight, href)
                                                        }
                                                    />
                                                )
                                            }
                                        })}
                                    </ul>
                                    {showLinkToInsights && (
                                        <strong>
                                            <Button
                                                name="insights-popover-button"
                                                label={label}
                                                variant="link"
                                                onClick={() => onLinkClick()}
                                                className="px-xs"
                                            />
                                        </strong>
                                    )}
                                </div>
                            )}
                            {/* Empty state */}
                            {completedInsights && !selectedInsightsSize && (
                                <EmptyState
                                    label="Watch for helpful tips and guidance here"
                                    className="px-none py-xs lgx:py-md lgx:px-lg"
                                />
                            )}
                        </Card>
                    </FloatingFocusManager>
                </div>
            )}
        </React.Fragment >
    )
}

/**
 * Sub Component: CampaignItem
 * -----------------------------------------------------------------------------
 *
 */
const CampaignItem: React.FC<InsightCampaignProps> = props => {
    /**
     * Hooks
     */
    const router = useRouter()
    const start = router.pathname === "/" ? "home" : "medications"

    const campaignId = props.action?.campaignId
    const campaignRoute = props.action?.route

    const campaignRouteUrl = campaignRoute ? new URL(campaignRoute) : null
    const oppId = campaignRouteUrl?.searchParams.get("opportunity_id")
    const opportunity_Id = oppId ? `?opportunity_id=${oppId}` : null

    // use regex here
    const isCampaignRoute =
        campaignRoute?.split("/").slice(3, 4)[0] == "campaign"
    const campaignGuid = isCampaignRoute
        ? campaignRoute?.split("/").slice(4, 5)[0].split("?").slice(0, 1)[0]
        : null
    const campaignLink = campaignGuid
        ? Routes.browseCampaign(campaignGuid) + opportunity_Id
        : campaignId
            ? Routes.browseCampaign(campaignId) + opportunity_Id
            : campaignRoute
    const nonRx = campaignNonRx(props.analyticsTitle, props.title)
    
    const href = campaignLink + "&start=" + start
    const label = props.action.label

    const dispatch = useDispatch()

    const isInsightCardFamilyMemberEnabled = useFeatureByName(
        Features.DFW_INSIGHT_CARD_FAMILY_MEMBER_ENABLED,
    )

    const campaignIcon = nonRx
        ? "check-circle-alt"
        : "pills" as IconTypes
    const campaignInsightLink = nonRx
        ? "non-rx-popover-campaign-insight-link"
        : "popover-campaign-insight-link"

    /**
     * Template
     */
    return (
        <li data-test={`${props.type}-popover-campaign-insights-item`}
            className="py-xs border-b border-muted last:border-none last:pb-none
                       hover:bg-gray-50 hover:dark:bg-dark-50"
        >
            <Clickable
                name={campaignInsightLink}
                analytics={{
                    data_analytics_id: campaignInsightLink,
                    rx_campaign_id: campaignGuid,
                    link_url: href,
                    link_text: label,
                }}
                onClick={() => {
                    dispatch(
                        actions.updateCampaigns({
                            isItemInitialized: false,
                            isInitialized: false,
                        }),
                    )
                    props.onClick(href)
                }}
                className="ml-xxs"
            >
                <div className="flex items-center space-x-xs">
                    {props.isNew && (
                        <Badge />
                    )}
                    {props.showInitials ? (
                        <AvatarStack
                            items={[
                                { icon: campaignIcon, size: "small" },
                                {
                                    label: props.initials,
                                    variant: "primary",
                                    size: "small",
                                },
                            ]}
                        />
                    ) : (
                        <Avatar icon={campaignIcon} size="small" />
                    )}

                    {isInsightCardFamilyMemberEnabled && (
                        <span className="mr-xxs">
                            Family member ID: {props.membershipId}
                        </span>
                    )}

                    <div className="flex-1">
                        <span>{props.title}</span>
                    </div>
                </div>
            </Clickable>
        </li>
    )
}

/**
 * Subcomponent: InsightItem
 * -----------------------------------------------------------------------------
 */

const InsightItem: React.FC<InsightCardProps> = props => {
    /**
     * Template variables
     */
    const insight = props.insight

    const isCareTeamIntro =
        insight.analyticsTitle === "care team intro" && !insight.action.url
    const isTimelineCTA =
        insight.title ===
        "See all of your meds, appointments and health-related details in one place." &&
        !insight.action.url
    const defineActionHref = () => {
        if (isCareTeamIntro) return Routes.providers()
        if (isTimelineCTA) return Routes.timeline()
        const insightUrl = insight.action?.url
        return insightUrl?.includes("care-gaps")
            ? insightUrl +
            (insightUrl?.includes("?") ? "&" : "?") +
            "insight=" +
            insight.insightId
            : insightUrl
    }

    const isInsightCardFamilyMemberEnabled = useFeatureByName(
        Features.DFW_INSIGHT_CARD_FAMILY_MEMBER_ENABLED,
    )
    const actionHref = defineActionHref()

    /**
     * Template
     */
    return (
        <li data-test={"insight-card-" + insight.insightId}
            className="py-xs border-b border-muted last:border-none last:pb-none
                       hover:bg-gray-50 hover:dark:bg-dark-50"
        >
            <Clickable
                className="ml-xxs"
                name="popover-insight-link"
                onClick={() => props.onClick(actionHref)}
            >
                <div className="flex items-center space-x-sm">
                    {props.insight.isNew && (
                        <Badge />
                    )}
                    {props.showInitials ? (
                        <AvatarStack
                            items={[
                                { icon: insight.icon, size: "small" },
                                {
                                    label: props.initials,
                                    variant: "primary",
                                    size: "small",
                                },
                            ]}
                        />
                    ) : (
                        <Avatar icon={insight.icon} size="small" />
                    )}
                    <div className="flex-1">
                        {isInsightCardFamilyMemberEnabled && (
                            <span className="mr-xxs">
                                Family member ID: {props.membershipId}
                            </span>
                        )}
                        <span>{insight.title}</span>
                    </div>
                </div>
            </Clickable>
        </li>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default InsightsPopover
