/**
 * Restart Chat Message
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useDispatch, actions } from "store"
import { Button } from "elements"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const RestartChat = () => {
    /**
     * Methods
     */
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(actions.initializeChat())
        localStorage?.setItem("CHAT_IS_VISIBLE", "true")
    }

    /**
     * Template
     */
    return (
        <div className="my-xs px-xs text-center">
            <div className="border-t border-muted my-sm" />
            <div className="text-muted">Chat session has ended</div>
            <Button
                name="restart-chat-button"
                label="New session"
                size="small"
                variant="secondary"
                rounded={true}
                className="mt-xxs min-h-[24px] px-[8px]"
                onClick={onClick}
            />
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default RestartChat
