/**
 * Chat Conversation
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions } from "store"
import { Button, Spinner, Icon } from "elements"
import ChatMessage from "./message"
import TypingIndicator from "./indicator"
import RestartChat from "./restart_chat"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly children?: React.ReactNode
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ChatConversation = (props: Props) => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()

    /**
     * Refs
     */
    const ref = React.useRef<HTMLDivElement>(null)

    /**
     * Application state
     */
    const chat = useSelector(state => state.chat)

    /**
     * Lifecycle: Scroll to bottom
     */
    React.useEffect(() => {
        scrollToBottom(true)
    }, [chat.messages, chat.isAgentTyping, chat.isChatEnded])

    /**
     * Methods
     */
    const initializeChat = () => {
        dispatch(actions.initializeChat())
    }
    const scrollToBottom = (smooth: boolean) => {
        setTimeout(() => {
            if (ref.current) {
                const behavior = smooth ? "smooth" : "auto"
                ref.current.scrollIntoView({ block: "end", behavior })
            }
        }, 10) // wait a tick while dom paints
    }

    /**
     * Define template variables
     */
    const isError = chat.status === "error"
    const isInitializing = chat.status !== "completed"

    /**
     * Template
     */
    return (
        <div className={`bg-white dark:bg-dark-200 ${props.className}`}>
            {/* Conversation */}
            {!isInitializing && !isError && (
                <div className="h-full overflow-y-auto hide-scrollbar">
                    <div className="p-xs" ref={ref}>
                        {/* Messages */}
                        {chat.messages.map((message, index) => (
                            <ChatMessage
                                message={message}
                                intentContext={chat.intentContext}
                                previous={chat.messages[index - 1]}
                                key={index}
                            />
                        ))}

                        {/* Agent typing indicator */}
                        {chat.isAgentTyping && (
                            <TypingIndicator className="mt-xxs" />
                        )}

                        {/* Restart chat message */}
                        {chat.isChatEnded && <RestartChat />}
                    </div>
                </div>
            )}

            {/* Loading */}
            {isInitializing && !isError && (
                <div className="flex items-center justify-center h-full">
                    <Spinner />
                </div>
            )}

            {/* Error */}
            {isError && (
                <div className="flex items-center justify-center h-full">
                    <div className="text-error text-center">
                        <Icon type="warning" />
                        <p className="mt-xs">Whoops, something went wrong.</p>
                        <Button
                            name="retry-chat-button"
                            label="Try again"
                            variant="link"
                            size="small"
                            className="min-h-[0px]"
                            onClick={initializeChat}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ChatConversation
