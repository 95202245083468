/**
 * Glance Cobrowse - Session ended modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon, Modal } from "elements"
import { CobrowseSessionEndReason } from "@/store/cobrowse/reducer"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly showModal: boolean
    readonly setShowModal: (b: boolean) => void
    readonly reason: CobrowseSessionEndReason
}
/**
 * Define template variables
*/

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const CobrowseEndedModal = (props: Props) => {
    const status: "success" | "error" = ["visitor", "agent"].includes(props.reason)
        ? "success"
        : "error"
    return (
        <Modal
            name="cobrowse-ended"
            size="small"
            isVisible={props.showModal}
            onDismiss={() => props.setShowModal(false)}
            noBorder={true}
        >
            <Modal.Content className="text-center">
                <Icon type={status === "error" ? "times-circle" : "check-circle"} size={56} className={`${status === "error" ? "text-error-200" : "text-success-200"} mb-sm`} />
                <p className="text-lg mb-none">{status === "error" ? "Session expired" : "Session ended"}</p>
            </Modal.Content>
        </Modal>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default CobrowseEndedModal
