import { App } from "@/store/app/reducer"
import { Account } from "@/store/account/reducer"
import Routes from "@/router"
import janusLogger from "./logger"

/**
 * Define template variables
 */
export const convertTemplateString = (input: string, forceBlank?: boolean) => {
    try{
        /**
         * First regex matches a template link of this format
         * [Google Chrome](href: "https://www.google.com/chrome/" target: "_blank")
         * [Learn more](/member/members/estimate-treatment-costs)
         * @label is `Google Chrome` and `Learn more`
         * @attributes is `href: "https://www.google.com/chrome/" target: "_blank"`
         * convertTemplateString returns for every match an html string of the format
         * <a href="https://www.google.com/chrome/" target="_blank" rel="noopener">Google Chrome</a>
         */
        const templateLink = /\[([^\]]*)]\s?\(([^)]*)\)/gi

        /**
         * strips https://account.regence.com from URL (sent to us in chat messages from Lex)
         * @param url relative path if url matches our own domain; else, returns original url
         */
        const relativePath = (url: string): string =>
            url.replace("https://account.regence.com", "")

        const formattedBody = input?.replace(templateLink, (...linkMatchWithAttributeGroup) => {
            const [, label, attributes] = linkMatchWithAttributeGroup
            const [, href] = /href:\s?"([^"]*)"/gi.exec(attributes) || [undefined,attributes]
            const [, target] = /target:\s?"([^"]*)"/gi.exec(attributes) || []
            /**
             * DomPurifier intentionally removes target because it's unsafe without rel="noopener" so we add [rel] here
             * https://web.dev/external-anchors-use-rel-noopener/
             */
            const attrs = forceBlank ||
                (target && target === "_blank")
                    ? `target="_blank" rel="noopener"`
                    : ""
            const result = `<a data-analytics-id="${label.split(" ").join("-").toLowerCase()}" href="${relativePath(href)}" ${attrs}>${label}</a>`
            return result
        }) || ""

        return formattedBody
    }catch(err){
        console.error("Failed to format markdown body", err)
        return input || ""
    }
}

/**
 * 
 * @param body 
 * @param tokens 
 * @param app 
 * @param account 
 * @returns 
 */
export const getBodyWithTokens = (body, tokens, app: App = undefined, account: Account = undefined) => {
    // eslint-disable-next-line functional/no-let
    let result = body
    const profile = account?.profile

    try {
        for (const i in tokens) {
            const { type, label, value } = tokens[i]
            if (/internalLink|ssoLink/.test(type)) {
                result = result?.replace(`{${i}}`, `[${label}](${value})`)
            } else if (/externalLink/.test(type)) {
                result = result?.replace(
                    `{${i}}`,
                    `[${label}](href:"${value}" target:"_blank")`,
                )
            } else if (
                /specialLink/.test(type) &&
                /usableFindDentistLink/.test(value)
            ) {
                if (app && account) {
                    const override = Routes.findDentistUsableDental({
                        zip: profile?.address?.postalCode?.slice(0, 5),
                        env:app?.environment,
                        planName: account?.plan?.usableDentalPlanName,
                        brandName: app?.brand,
                        subBrand: profile?.dentalSellingAffiliate,
                    })
                    result = result?.replace(
                        `{${i}}`,
                        `[${label}](href:"${override}" target:"_blank")`,
                    )
                }
                result = result?.replace(
                    `{${i}}`,
                    `[${label}](href:"${value}" target:"_blank")`,
                )
            } else {
                // unknown link type
                janusLogger.error({
                    message: "Unknown link type in markdown",
                })
                result = result?.replace(
                    `{${i}}`,
                    "",
                )
            }
        }
    } catch (err) {
        janusLogger.error({
            message: "Failed parsing markdown string body with tokens",
            err,
        })
    }
    return result
}
