/**
 * Typing Indicator
 * -----------------------------------------------------------------------------
 * ( • • • )
 */
import React from "react"

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const TypingIndicator = (props: React.HTMLAttributes<HTMLDivElement>) => {
    /**
     * Styles
     */
    // prettier-ignore
    const wrap = "bg-primary-50 dark:bg-dark-100 inline-block h-lg px-xs rounded-circle"
    const dot =
        "h-xxs w-xxs bg-gray-200 dark:bg-white bg-opacity-50 rounded-circle"

    /**
     * Template
     */
    return (
        <div className={`${wrap} ${props.className || ""}`}>
            <div className="h-full flex items-center space-x-xxxs">
                <div className={`${dot} animate-pulse`} />
                <div className={`${dot} animate-pulse`} />
                <div className={`${dot} animate-pulse`} />
            </div>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default TypingIndicator
