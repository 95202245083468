/**
 * Chat Header
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useDispatch, actions } from "store"
import { Button } from "elements"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
type Props = React.HTMLAttributes<HTMLDivElement>

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ChatHeader = (props: Props) => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()

    /**
     * Application state
     */
    const chat = useSelector(state => state.chat)

    /**
     * Methods
     */
    const showEndChatModal = () => {
        dispatch(actions.toggleEndChatModal())
    }

    /**
     * Template
     */
    return (
        <div
            className={`h-[72px] bg-primary-300 flex items-center justify-between px-xs rounded-t-sm ${props.className}`}
        >
            <h5 className="text-white mb-none">Customer chat</h5>
            <div>
                {!chat.isChatEnded && (
                    <Button
                        name={chat.intentContext || "chat-options-button"}
                        label="End chat"
                        onClick={showEndChatModal}
                        size="small"
                    />
                )}
            </div>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ChatHeader
