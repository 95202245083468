/**
 * Effective Date
 * -----------------------------------------------------------------------------
 */

import React from "react"
import { useSelector } from "store"

const getLatestCoverage = (coverages, isTerminated, isActive) => {
    if (isTerminated) {
        return coverages.sort((a, b) => (new Date(b.startDate) > new Date(a.startDate)) ? 1 : -1)[0]
    } else if (isActive) {
        return coverages
            .filter((cov) => cov.status === "A")
            .sort((a, b) => (new Date(b.startDate) > new Date(a.startDate)) ? 1 : -1)[0]
    } else {
        return coverages
            .filter((cov) => cov.status === "P")
            .sort((a, b) => (new Date(b.startDate) > new Date(a.startDate)) ? 1 : -1).pop()
    }
}

const formatDisplayDate = (isTerminated, latestCoverage) => {
    const coverageDate = isTerminated ? latestCoverage.endDate : latestCoverage.startDate;
    const splitDate = coverageDate.split("-");
    const month = splitDate[1]
    const day = splitDate[2]
    const year = splitDate[0];
    return `${month}-${day}-${year}`;
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const EffectiveDate = () => {

    const { coverages } = useSelector(state => state.account.profile)
    const coverageIsActive = (coverage) => coverage.status === "A";
    const coverageIsPending = (coverage) => coverage.status === "P";
    const isActive = coverages ? coverages.some(coverageIsActive) : false;
    const isPending = coverages ? coverages.some(coverageIsPending) : false;
    const isTerminated = !isActive && !isPending;
    const latestCoverage = getLatestCoverage(coverages, isTerminated, isActive);
    const dateLabel = isTerminated ? "Terminated date" : "Effective date";
    const displayDate = formatDisplayDate(isTerminated, latestCoverage);
    /**
      * Template
      */
    return (
        <div className="mt-xxs">
            <label>{dateLabel}</label>
            <div>{displayDate}</div>
        </div>
    )
 
}
 
 /**
  * Export component
  * -----------------------------------------------------------------------------
  */
 export default EffectiveDate
 