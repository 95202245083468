/**
 * Layout Header
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Routes from "router"
import { useTranslation } from "react-i18next"
import { Button, Link } from "elements"
import { Props as ButtonProps } from "elements/button"
import AccountDropdown from "./account_dropdown"
import Logo from "components/logo"
import BrandLogo from "components/brand_logo"
import GlobalAlerts from "./global-alerts"
import { clearAapSession } from "@/store/rx/pharmacist-inquiries/utils"
import { useSelector, useFeatures } from "store"
import Features from "utils/features"
import { employerGroupByNameOrCategory } from "store/account/utils"
import { EmployerGroup } from "store/account/types"
import InsightsPopover from "../insights/insights_popover"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly showNavigation?: (value: boolean) => void
    readonly variant?: "default" | "simple"
    readonly contentClass?: string
    readonly buttonLeft?: ButtonProps
    readonly buttonRight?: ButtonProps
    readonly progress?: number
    readonly isNoLogoLink?: boolean
    readonly disableShadow?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Header: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const { t } = useTranslation()

    /**
     * Application state
     */
    const { profile } = useSelector(state => state.account)
    const campaigns = useSelector(state => state.campaigns)

    /**
     * Feature flags
     */

    const {
        [Features.DASHBOARD__FIND_CARE_BUTTON]: findCareButtonFeature,
        [Features.JOINT_ADMIN_ENABLED]: isJointAdminEnabled,
        [Features.JOINT_ADMIN]: isJointAdminEligible,
        [Features.DASHBOARD_LANGUAGE_SPANISH]: showSpanishDashboard } =
        useFeatures()

    /**
     * Define template variables
     */
    const cobrand =
        profile.employerGroupName === "na"
            ? employerGroupByNameOrCategory(profile.employerGroupCategory)
            : employerGroupByNameOrCategory(profile.employerGroupName)
    const isJADM = (isJointAdminEnabled && isJointAdminEligible)

    /**
     * Template: Simple Variant
     */
    if (props.variant === "simple") {
        return (
            <div className="sticky top-none z-header w-full bg-white dark:bg-dark-200 border-b-xxl border-light-100 dark:border-black">
                <div
                    className={`h-xxxl py-none flex items-center justify-between ${props.contentClass}`}
                >
                    <div className="w-[20%] lgx:w-[30%]">
                        {props.buttonLeft && (
                            <Button
                                {...props.buttonLeft}
                                variant="link"
                                className="px-xxxs focus-offset"
                            />
                        )}
                    </div>
                    <BrandLockup isNoLogoLink={props.isNoLogoLink} />
                    <div className="w-[20%] lgx:w-[30%] text-right ">
                        {props.buttonRight && (
                            <Button
                                {...props.buttonRight}
                                variant="link"
                                className="px-xxxs focus-offset"
                            />
                        )}
                    </div>
                </div>

                {/* Progress bar */}
                {props?.progress >= 1 && (
                    <div className={`absolute top-xxxl w-full h-[5px] py-none`}>
                        <div
                            className="h-full rounded bg-primary dark:bg-accent"
                            style={{ width: props.progress + "%" }}
                        />
                    </div>
                )}
            </div>
        )
    }

    /**
     * Template: Default
     */
    return (
        <div className={`bg-white dark:bg-dark-300 sticky top-none z-header w-full ${props.disableShadow ? "shadow md:shadow-none" : "shadow"}`}>
            {/* Global Alerts */}
            <GlobalAlerts position="header" />

            <div className="h-xxxl flex items-center">
                {!!props.showNavigation && (
                    <div className="lgx:hidden mr-xxxs px-xxs">
                        <Button
                            name="toggle-navigation-button"
                            icon="bars"
                            variant="link"
                            onClick={() => props.showNavigation(true)}
                        />
                    </div>
                )}
                <BrandLockup isNoLogoLink={props.isNoLogoLink} />

                <div className="flex flex-row shrink-0 ml-auto items-center text-right lgx:space-x-sm pr-xs">
                    {/* Language */}
                    {showSpanishDashboard && (
                        <Button
                            name="header-spanish-dashboard-button"
                            className="hidden lgx:block"
                            label={t("navigation.inSpanish")}
                            variant="secondary"
                            size="small"
                            href={Routes.dashboardES()}
                        />
                    )}
                    {!isJADM && (
                        <InsightsPopover campaigns={campaigns}/>
                    )}
                    {findCareButtonFeature && (
                        <Button
                            name="header-find-care-button"
                            className="hidden lgx:block"
                            label={t("navigation.findCare")}
                            variant="primary"
                            size="small"
                            href={Routes.findCare()}
                        />
                    )}
                    <AccountDropdown name="account-dropdown" />
                </div>
            </div>
        </div>
    )
}

/**
 * Subcomponent: Brand Logo Lockup
 * -----------------------------------------------------------------------------
 *
 */
const BrandLockup = (props: {
    readonly isNoLogoLink?: boolean
}) => {
    /**
     * Application state
     */
    const { brand } = useSelector(state => state.app)
    const { profile } = useSelector(state => state.account)

    /**
     * Define template variables
     */
    /** EmployerGroupInfo containing employerGroupCategory and employerGroupName set to same values in DFARS. 
     * employerGroupName is adjusted in DFARS so as to consume only in deriving cobrand with below logic
     */
    const cobrand =
        profile.employerGroupName === "na"
            ? employerGroupByNameOrCategory(profile.employerGroupCategory)
            : employerGroupByNameOrCategory(profile.employerGroupName)

    /**
     * Template: Regence (Legacy)
     */
    if (brand === "regence-legacy") {
        return (
            <div className="flex items-center space-x-xs lgx:space-x-sm px-xs">
                {props.isNoLogoLink
                    ?
                    <span className="flex items-center w-[170px] h-lg">
                        <Logo />
                    </span>
                    :
                    <Link
                        href={Routes.home()}
                        name="header-logo-link"
                        unstyled={true}
                        onClick={() => clearAapSession()}
                        className="flex items-center w-[170px] h-lg"
                    >
                        <Logo />
                    </Link>
                }
                {cobrand && <CobrandLogo {...cobrand} />}
            </div>
        )
    }

    /**
     * Template: Default
     */
    return (
        <div className="h-full flex items-center">
            {props.isNoLogoLink
                ?
                <span className="h-full flex items-center px-xs lgx:px-sm">
                    <BrandLogo className={`${cobrand ? "h-sm lgx:h-md" : "h-md"}`} />
                </span>
                :
                <Link
                    href={Routes.home()}
                    name="header-logo-link"
                    unstyled={true}
                    onClick={() => clearAapSession()}
                    className="lgx:pl-sm h-full flex items-center"
                >
                    <BrandLogo className={`${cobrand ? "h-sm lgx:h-md" : "h-md"}`} />
                </Link>
            }
            {cobrand && (
                <div className="ml-sm">
                    <CobrandLogo {...cobrand} />
                </div>
            )}
        </div>
    )
}

/**
 * Subcomponent: Co-brand logo
 * -----------------------------------------------------------------------------
 */
const CobrandLogo = (props: EmployerGroup) => {
    /**
     * Template
     */
    if (props.id === "cis") {
        return (
            <div>
                <img
                    src={`/assets/img/${props.src}-inverted.svg`}
                    alt={props.name}
                    className="max-h-[20px] lgx:max-h-[30px] hidden dark:block"
                />
                <img
                    src={`/assets/img/${props.src}.svg`}
                    alt={props.name}
                    className="max-h-[20px] lgx:max-h-[30px] block dark:hidden"
                />
            </div>
        )
    }

    return (
        <div>
            <a href="/">
                <img
                    src={`/assets/img/${props.src}-inverted.svg`}
                    alt={props.name}
                    className="max-h-[20px] lgx:max-h-[30px] hidden dark:block"
                />
                <img
                    src={`/assets/img/${props.src}.svg`}
                    alt={props.name}
                    className="max-h-[20px] lgx:max-h-[30px] block dark:hidden"
                />
            </a>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Header
