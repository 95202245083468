/**
 * Account Dropdown
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Routes, { useRouter } from "router"
import { actions, useDispatch, useFeatures, useSelector } from "store"
import { ActionSheet, ActionMenu, Accordion, Avatar, Icon } from "elements"
import { IconTypes } from "elements"
import { tagEvent } from "@/utils/analytics"
import { MEMBER_CARDS_PREV_URL_KEY } from "@/store/member-cards/utils"
import { ACCOUNT_SETTINGS_PREV_URL_KEY, ACCOUNT_SETTINGS_SMS_IN_PROGRESS_KEY } from "@/store/account-settings/utils"
import Features from "@/utils/features"
import EffectiveDate from "../dashboard/joint-admin/effective_date"
import { cleanupSessionStorage, cleanupLocalStorage } from "@/utils/window-storage"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
type ActionMenuProps = React.ComponentProps<typeof ActionMenu>
type ActionMenuItems = ActionMenuProps["items"]
interface Props {
    readonly name: string
    readonly layout?: "default" | "accordion"
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const AccountDropdown: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const router = useRouter()
    const dispatch = useDispatch()

    /**
     * Application state
     */
    const app = useSelector(state => state.app)
    const { profile, plan } = useSelector(state => state.account)

    /**
     * Define template variables
     */
    const isProd = app.environment === "prd"
    const { firstName, lastName, relationship } = profile
    const fullName = firstName + (firstName && lastName ? " " : "") + lastName
    const { memberId, groupNumber } = plan
    const messageCenterNotifications =
        profile.messageCenterNotifications &&
            profile.messageCenterNotifications > 0
            ? { badge: profile.messageCenterNotifications + "" } // converting messageCenterNotifications from number to string
            : {}

    /**
     * Feature Toggle
     */
    const {
        [Features.DFW_MY_ACCOUNT_ENABLED]: isDFWMyAccountEnabled,
        [Features.JOINT_ADMIN_ENABLED]: isJointAdminEnabled,
        [Features.JOINT_ADMIN]: isJointAdmin,
        [Features.JOINT_ADMIN_WITH_MEMBER_CARD]: isJointAdminWithCard,
    } = useFeatures()

    /**
     * Membership details (Regence only)
     */
    const MembershipDetails = () => (
        <div>
            <label>Member ID</label>
            <div className="mb-xxs">{memberId}</div>
            <label>Group Number</label>
            <div>{groupNumber}</div>
            {isJointAdminEnabled && isJointAdmin && (<EffectiveDate />)}
        </div>
    )

    const signoutHandler = async () => {
        /**
         * Sign out tealium event
         */
        tagEvent({
            tealium_event: "signed_out",
            page_title: document.title,
            relationship: relationship,
        })

        /**
         * Check if the user was in the middle of activating SMS phone number, so we can cancel it
         */
        if (window.localStorage.getItem(ACCOUNT_SETTINGS_SMS_IN_PROGRESS_KEY) === "true") {
            const response = await dispatch(actions.cancelSMSActivation())
            if (response) {
                /**
                 * Cleanup window storages required before signout.
                 */
                window.localStorage.setItem(ACCOUNT_SETTINGS_SMS_IN_PROGRESS_KEY, "false")
                cleanupSessionStorage()
                cleanupLocalStorage()
                window.location.assign(Routes.signout())
            }
        } else {
            /**
             * Cleanup window storages required before signout.
             */
            cleanupSessionStorage()
            cleanupLocalStorage()
            window.location.assign(Routes.signout())
        }
    }

    /**
     * ActionMenu items
     */
    const items: ActionMenuItems = [
        // Add 'node' element conditionaly
        ...[{ node: <MembershipDetails /> }],
        ...(isDFWMyAccountEnabled ? [{
            label: "My Account",
            analytics: {
                data_analytics_id: "my-account-menu-link",
                link_url: Routes.accountInformation(),
                link_text: "My Account",
            },
            onClick: () => {
                if (window.location.pathname === "/") {
                    window.sessionStorage.setItem(
                        ACCOUNT_SETTINGS_PREV_URL_KEY,
                        Routes.home(),
                    )
                } else {
                    window.sessionStorage.setItem(
                        ACCOUNT_SETTINGS_PREV_URL_KEY,
                        window.location.pathname,
                    )
                }
                router.push(Routes.accountInformation())
            },
            hasDivider: true,
        }] : [{
            label: "Account settings",
            analytics: {
                data_analytics_id: "account-settings-menu-link",
                link_url: Routes.settings(),
                link_text: "Account settings",
            },
            onClick: () => {
                router.push(Routes.settings())
            },
            hasDivider: true,
        }]),
        ...(!(isJointAdminEnabled && isJointAdmin) ? [{
            label: "Message center",
            analytics: {
                data_analytics_id: "message-center-menu-link",
                link_url: Routes.messages(),
                link_text: "Message center",
            },
            onClick: () => router.push(Routes.messages()),
            ...messageCenterNotifications,
        }] : []),
        ...((!isJointAdminEnabled || !isJointAdmin || isJointAdminWithCard) ? [{
            label: "Member ID card",
            analytics: {
                data_analytics_id: "member-id-card-menu-link",
                link_url: Routes.memberCards(),
                link_text: "Member ID card",
            },
            onClick: () => {
                if (window.location.pathname === "/") {
                    window.sessionStorage.setItem(
                        MEMBER_CARDS_PREV_URL_KEY,
                        Routes.home(),
                    )
                } else {
                    window.sessionStorage.setItem(
                        MEMBER_CARDS_PREV_URL_KEY,
                        window.location.pathname,
                    )
                }
                router.push(Routes.memberCards())
            },
        }] : []),
        ...(!(isJointAdminEnabled && isJointAdmin) ? [{
            label: "Support",
            analytics: {
                data_analytics_id: "support-menu-link",
                link_url: Routes.support(),
                link_text: "Support",
            },
            onClick: () => router.push(Routes.support()),
        }] : []),
        // prettier-ignore
        ...(!isProd ? [{
            label: "Experiments",
            href: "/experiments",
            icon: "flask" as IconTypes,
            hasDivider: true,
        }] : []),
        {
            name: "sign-out-button",
            label: "Sign out",
            onClick: signoutHandler,
            // href: Routes.signout(),
            hasDivider: true,
        },
    ]

    /**
     * Template: Accordion layout
     */
    if (props.layout === "accordion") {
        return (
            <Accordion
                name={props.name}
                iconNodes={[
                    <Icon type="angle-up-sm" className="text-white mx-sm" />,
                    <Icon type="angle-down-sm" className="text-white mx-sm" />,
                ]}
                inverted={true}
                items={[
                    {
                        name: props.name + "-toggle",
                        label: (
                            <div className="flex-1 min-w-0 flex space-x-xs items-center px-sm text-white text-left">
                                <div
                                    data-test="fullName"
                                    className="flex-1 min-w-0 truncate"
                                >
                                    {fullName}
                                </div>
                            </div>
                        ),
                        content: (
                            <div className="bg-white">
                                <ActionMenu
                                    name={props.name + "-items"}
                                    items={items}
                                />
                            </div>
                        ),
                        button: { className: "focus-inset" },
                    },
                ]}
            />
        )
    }

    /**
     * Template: Default layout
     */
    return (
        <ActionSheet
            name={props.name}
            analytics={{ link_text: "account dropdown" }} // Overwrite PII data DSEXPC-2553
            label={fullName}
            labelClass="max-w-[150px] truncate hidden lgx:block"
            align="right"
            type="select"
            size="small"
            iconNode={
                <Avatar label={fullName} size="xsmall" variant="primary" />
            }
            items={items}
        />
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default AccountDropdown
