/**
 * Chat Message Bubble
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Message } from "store/chat/reducer"
import { useSelector, useDispatch, actions } from "store"
import { Button, Markdown } from "elements"
import { formatDate } from "utils/date"
import { convertTemplateString } from "@/utils/markdown"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly message: Message
    readonly previous?: Message
    readonly intentContext?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ChatMessage = (props: Props) => {
    /**
     * Define template variables
     */
    const isUser = props.message.userAlias?.includes("You")
    const isAgent = !isUser
    const isPrevious = props.previous?.userAlias === props.message.userAlias

    /**
     * Styles
     */
    const base = "text-left rounded-md px-xs py-xxs min-w-[0px] inline-block"
    const user = "bg-primary-300 dark:bg-primary text-white ml-xl"
    const agent = "bg-primary-50 dark:bg-dark-100 mr-xl"
    const bubbleClass = `${base} ${isAgent ? agent : user}`

    /**
     * Template: System Messages
     */
    if (props.message.userAlias === "System") {
        return <SystemMessage {...props.message} />
    }

    /**
     * Template: User + Bot Messages
     */
    return (
        <div className={isPrevious ? "mt-xxxs" : "mt-xs"}>
            <div className={isAgent ? "" : "text-right"}>
                <CardContent
                    message={props.message}
                    className={bubbleClass}
                    intentContext={props.intentContext}
                    isUser={isUser}
                />
            </div>
        </div>
    )
}

/**
 * Subcomponent: SystemMessage
 * -----------------------------------------------------------------------------
 */
const SystemMessage = (message: Message) => {
    return (
        <div className="my-xs px-xs text-center">
            <div className="text-muted">{message.text}</div>
            <div className="text-xs mt-xxxs">
                {formatDate(new Date(message.createdAt), "p")}
            </div>
        </div>
    )
}

/**
 * Subcomponent: CardContent
 * -----------------------------------------------------------------------------
 */
const CardContent = (props: {
    readonly message: Message
    readonly className: string
    readonly intentContext?: string
    readonly isUser: boolean
}) => {
    /**
     * Component state
     */
    const [selected, setSelected] = React.useState("")

    /**
     * Application state
     */
    const chat = useSelector(state => state.chat)

    /**
     * Methods
     */
    const dispatch = useDispatch()
    const onClick = (input: string, value: string) => {
        if (value === "card_response_end_chat") {
            // Show "end chat" modal
            dispatch(actions.toggleEndChatModal())
        } else {
            // Send button message
            if (!selected) {
                dispatch(actions.sendMessage({ input, value }))
                document.getElementById("chat-input").focus()
                setSelected(value)
            }
        }
    }

    /**
     * Define template variables
     */
    const text = props.message?.text
    const buttons = props.message?.buttons
    const isButtons = buttons && props.message?.buttons?.length > 0
    const isDisabled = chat.isChatEnded

    /**
     * Template
     */
    return (
        <React.Fragment>
            {text && (
                <div className={props.className}>
                    {props.isUser && <div>{text}</div>}
                    {!props.isUser && (
                        <Markdown
                            content={convertTemplateString(text, true)}
                            config={{ ADD_ATTR: ["target"] }}
                        />
                    )}
                    <small className="text-xs">
                        {formatDate(new Date(props.message.createdAt), "p")}
                    </small>
                </div>
            )}
            {isButtons && (
                <div className="mr-xl">
                    {buttons.map((button, index) => (
                        <Button
                            name={props.intentContext || ""}
                            label={button.label}
                            onClick={() => onClick(button.label, button.value)}
                            size="small"
                            variant={
                                selected === button.value
                                    ? "primary"
                                    : "secondary"
                            }
                            rounded={true}
                            disabled={isDisabled}
                            key={index}
                            className="block mt-xxs min-h-[24px] px-[8px]"
                        />
                    ))}
                </div>
            )}
        </React.Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ChatMessage
